import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import Editor from "@monaco-editor/react";
import { editor } from "monaco-editor";
import {
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  AppBar,
  IconButton,
  Toolbar,
} from "@material-ui/core";
import axios from "axios";
//import play arrow icon from mui old version
import PlayArrowIcon from "@material-ui/icons/PlayArrow";

import GroupAddIcon from "@material-ui/icons/GroupAdd";
import PersonIcon from "@material-ui/icons/Person";
import CircularProgress from "@material-ui/core/CircularProgress";
//import snackbar
import Snackbar from "@material-ui/core/Snackbar";
import "./App.css";
//import common drawer js from same folder
import CommonDrawer from "./CommonDrawer.js";
import CanvasEditor from "./CanvasEditor";
//navigate
import { useNavigate } from "react-router-dom";
//import firebase
import firebase from "firebase/compat/app";

const firebaseConfig = {
  apiKey: "AIzaSyCDM7KoL4QPJX1gln60thnslUukA4_Nd-U",
  authDomain: "ai-game-maker.firebaseapp.com",
  projectId: "ai-game-maker",
  storageBucket: "ai-game-maker.appspot.com",
  messagingSenderId: "100852636069",
  appId: "1:100852636069:web:d36e2204a6e62cd15fb04c",
  measurementId: "G-0P6M2SNKKW",
};

function SceneEditor() {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }
  const navigate = useNavigate();
  if (localStorage.getItem("uuid") === null) {
    navigate("/");
  }

  const auth = firebase.auth();

  const [files, setFiles] = useState({
    "index.js": 'console.log("Hello, world!");',
  });
  const [userPrompt, setUserPrompt] = useState("");
  const [generating, setGenerating] = useState(false);

  const [currentFile, setCurrentFile] = useState("index.js");

  const [playClicked, setPlayClicked] = useState(false);
  const [explanation, setExplanation] = useState("");
  const [imagesInfo, setImagesInfo] = useState(localStorage.getItem("scene"));

  //set interval to keep updating images
  useEffect(() => {
    const interval = setInterval(() => {
      var raw = localStorage.getItem("scene");
      var finalArray = [];
      var raw2 = JSON.parse(raw);
      var raw3 = [];
      for (let i in raw2) {
        finalArray.push({
          x: Math.floor(raw2[i].x),
          y: Math.floor(raw2[i].y),
          height: Math.floor(raw2[i].height),
          width: Math.floor(raw2[i].width),
          angle: Math.floor(raw2[i].rotation),
          image: raw2[i].image,
          imageSrc: raw2[i].src,
        });
        //IF ANGLE IS NULL, SET IT TO 0
        if (!finalArray[i].angle) {
          finalArray[i].angle = 0;
        }
        raw3.push(raw2[i].target);
      }
      //json parse every element in raw3
      for (let i in raw3) {
        //check if JSON.parse(raw3[i]).attrs is not
        //check if raw3[i] is a valid json
        if (!raw3[i]) {
          raw3[i] = JSON.stringify({});
        }

        raw3[i] = JSON.parse(raw3[i]).attrs;
        if (raw3[i]) {
          raw3[i].x = Math.floor(raw3[i].x);
          raw3[i].y = Math.floor(raw3[i].y);
          //height
          raw3[i].height = Math.floor(raw3[i].height);
          //width
          raw3[i].width = Math.floor(raw3[i].width);
          raw3[i].angle = Math.floor(raw3[i].rotation);
        }
      }

      //pretty print
      //check if raw3 is a valid json,
      //if not, set it to empty array
      if (!raw3) {
        raw3 = [];
      }

      var finalCode = `import { SmartGameObject } from "./Gameobject.js";`;
      for (let i in finalArray) {
        finalCode += `let object${i} = new SmartGameObject(${
          //finalArray[i] is a json object . I want it to be written in code format like {a :1}, not string format like "{a:1}"
          JSON.stringify(finalArray[i], null, 2)
        });\n`;
      }

      var pretty = JSON.stringify(finalArray, null, 2);

      setImagesInfo(finalCode);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  function requestCreateGame() {
    console.log("submitting code");
    //make a post request to the backend
    const URL =
      "https://us-central1-ai-game-maker.cloudfunctions.net/api/createGame";
    axios
      .post(URL, {
        code: files[currentFile],
        uuid: localStorage.getItem("uuid"),
      })
      .then((res) => {
        console.log(res);
        //go to res.data.publicURL IN NEW PAGE
        setPlayClicked(false);
        window.open(res.data.url, "_blank");
      });
  }
  return (
    <>
      <CommonDrawer />
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={playClicked}
        message="Loading game..."
        //green snackbar for success
        //success
        severity="success"
      />
      <div
        //className="App"
        style={{
          //backgroundColor: "#FFFFFF",
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          // justifyContent: "space-evenly",
        }}
      >
        <AppBar
          position="static"
          style={{
            backgroundColor: "#000000",
            height: "7vh",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            //self aligns to the top
            alignSelf: "flex-start",
          }}
        >
          <Typography
            variant="h6"
            style={{
              flexGrow: 1,
              //bold
              fontWeight: 600,
              paddingLeft: "20px",
            }}
          >
            Scene Editor Under Construction🚧⚒️
          </Typography>

          <Toolbar>
            <Typography
              variant="h6"
              style={{
                flexGrow: 1,
                //bold
                fontWeight: 600,
                paddingRight: "4vw",
              }}
            >
              12✨
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => {
                if (!playClicked) {
                  requestCreateGame();
                  //set is playing to true
                  setPlayClicked(true);
                }
              }}
            >
              {playClicked ? (
                //loading icon pink
                <CircularProgress
                  style={{
                    color: "#FF00FF",
                    //make it smaller
                    width: "30px",
                    height: "30px",
                  }}
                />
              ) : (
                <PlayArrowIcon
                  style={{
                    //make it smaller
                    width: "30px",
                    height: "30px",
                  }}
                />
              )}
            </IconButton>
            {/* <IconButton edge="start" color="inherit" aria-label="menu">
                  <GroupAddIcon
                    style={{
                      //make it smaller
                      width: "30px",
                      height: "30px",
                    }}
                  />
                </IconButton> */}
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => {
                //set firebase auth state to null
                auth.signOut();

                //rmeove uuid from local storage
                localStorage.removeItem("uuid");
                //redirect to login page with react router navigate

                navigate("/");
              }}
            >
              <PersonIcon
                style={{
                  //make it smaller
                  width: "30px",
                  height: "30px",
                }}
              />
            </IconButton>
          </Toolbar>
        </AppBar>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "80%",
            height: "80vh",
            alignSelf: "center",
            backgroundColor: "#1E1E1E",
            color: "#FFF",
            borderRadius: "10px",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
            padding: "20px",
            marginTop: "30px",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              display: "flex",

              flexDirection: "column",
              width: "65%",

              alignSelf: "left",
            }}
          >
            {" "}
            <CanvasEditor />
          </div>

          <div //the code editor paer
            style={{
              display: "flex",
              flexDirection: "column",
              width: "35%",

              alignItems: "center",
              gap: "20px",
            }}
          >
            <TextField
              id="outlined-multiline-static"
              label="Copy to top of your code"
              multiline
              rows={25}
              //available variants are outlined, filled, and standard
              variant="filled"
              value={imagesInfo}
              //outlined in white and text in white
              style={{
                backgroundColor: "#FFFFFF",
                color: "#FFFFFF",
                borderRadius: "10px",
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
                width: "80%",

                alignSelf: "center",

                overflowX: "hidden",
              }}
            />

            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                //IN NEW TAB , but not popup
                window.open("https://discord.gg/RkH98RgVDr", "_blank");
              }}
              style={{
                // backgroundColor: "#536DFE"
                // blue and green gradient background
                background: "linear-gradient(90deg, #00FFFF 0%, #00FF00 100%)",

                color: "#000",
                fontWeight: 600,
                borderRadius: "10px",
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
                width: "80%",
                alignSelf: "center",
              }}
            >
              <img
                src={
                  "https://www.freepnglogos.com/uploads/discord-logo-png/discord-logo-logodownload-download-logotipos-1.png"
                }
                style={{
                  width: "20px",
                  height: "20px",
                  marginRight: "10px",
                }}
              />
              Join Others on Discord!
            </Button>
            {/* <Button
              variant="contained"
              color="primary"
              onClick={() => {
                //IN NEW TAB , but not popup
                window.open(
                  "https://github.com/lil-shake-code/helicity.ai",
                  "_blank"
                );
              }}
              style={{
                // backgroundColor: "#536DFE"
                // light blue and darker visually appealing blue gradient background
                background: "linear-gradient(90deg, #00FFFF 0%, #00BFFF 100%)",

                color: "#000",
                fontWeight: 600,
                borderRadius: "10px",
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
                width: "80%",
                alignSelf: "center",
              }}
            >
              <img
                src={"https://cdn-icons-png.flaticon.com/512/25/25231.png"}
                style={{
                  width: "20px",
                  height: "20px",
                  marginRight: "10px",
                }}
              />
              RUN LOCALLY
            </Button> */}
          </div>
        </div>
      </div>
    </>
  );
}
export default SceneEditor;
