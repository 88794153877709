import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import Editor from "@monaco-editor/react";
import { editor } from "monaco-editor";
import {
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  AppBar,
  IconButton,
  Toolbar,
} from "@material-ui/core";
import axios from "axios";
//import play arrow icon from mui old version
import PlayArrowIcon from "@material-ui/icons/PlayArrow";

import GroupAddIcon from "@material-ui/icons/GroupAdd";
import PersonIcon from "@material-ui/icons/Person";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";

import CircularProgress from "@material-ui/core/CircularProgress";
//import snackbar
import Snackbar from "@material-ui/core/Snackbar";
import "./App.css";
//import common drawer js from same folder
import CommonDrawer from "./CommonDrawer.js";

//navigate
import { useNavigate } from "react-router-dom";
//import firebase
import firebase from "firebase/compat/app";

import { Toaster, toast } from "react-hot-toast";
//import firebase rtdb
import "firebase/compat/database";
import Blockly from "./Blockly.js";
import { ref } from "yup";

const firebaseConfig = {
  apiKey: "AIzaSyCDM7KoL4QPJX1gln60thnslUukA4_Nd-U",
  authDomain: "ai-game-maker.firebaseapp.com",
  projectId: "ai-game-maker",
  storageBucket: "ai-game-maker.appspot.com",
  messagingSenderId: "100852636069",
  appId: "1:100852636069:web:d36e2204a6e62cd15fb04c",
  measurementId: "G-0P6M2SNKKW",
  //rtdb
  databaseURL: "https://ai-game-maker-default-rtdb.firebaseio.com/",
};
var db;
function GIDE() {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
    const db1 = firebase.database();
    db = db1;
  } else {
    firebase.app(); // if already initialized, use that one
    const db1 = firebase.database();
    db = db1;
  }
  const navigate = useNavigate();
  if (localStorage.getItem("uuid") === null) {
    navigate("/");
  }

  const auth = firebase.auth();

  //URL params
  const params = new URLSearchParams(window.location.search);
  const projectName = params.get("project");
  const [plan, setPlan] = useState(0);

  const [code, setCode] = useState("");
  const [codeWI, setCodeWI] = useState("");
  const [playClicked, setPlayClicked] = useState(false);
  const [credits, setCredits] = useState("Loading");
  const [portalClicked, setPortalClicked] = useState(false);
  useEffect(() => {
    refreshCredits();
  }, []);
  //use effect play clicked
  useEffect(() => {
    if (playClicked) {
      //toast loading game
      toast("Loading game...");
    }
  }, [playClicked]);

  useEffect(() => {
    //addition of import statements at the top of the code
    const importStatements = `
import { Engine } from "./Engine.js";
import { GameObject } from "./GameObject.js";
import { Input } from "./Input.js"; \n`;

    //if its there in the code, dont add it again
    if (!code.includes(importStatements)) {
      setCode(importStatements + code);
    }
    //BUT set the code without import statements in codeWI
    //remove import statements
    setCodeWI(code.replace(importStatements, ""));
  }, [code]);

  function requestCreateGame() {
    //if credits <2 toast insufficient credits
    if (typeof credits == "number") {
      if (credits < 2) {
        setPlayClicked(false);

        toast.error("Insufficient Credits, please upgrade your plan!");
        return;
      }
    }
    console.log("submitting code");
    //make a post request to the backend
    const URL =
      "https://us-central1-ai-game-maker.cloudfunctions.net/api/create3dGame";
    axios
      .post(URL, {
        code: codeWI, //code without imports
        uuid: localStorage.getItem("uuid"),
        //game title
        title: projectName,
      })
      .then((res) => {
        console.log(res);
        //go to res.data.publicURL IN NEW PAGE
        setPlayClicked(false);
        window.open(res.data.url, "_blank");

        //refresh credits
        refreshCredits();
      });
  }

  function refreshCredits() {
    console.log("refreshing credits");
    //make a post request to the backend
    const URL =
      "https://us-central1-ai-game-maker.cloudfunctions.net/api/RefreshCredits";
    axios
      .post(URL, {
        uuid: localStorage.getItem("uuid"),
      })
      .then((res) => {
        // alert(JSON.stringify(res.data));
        var c = res.data.credits;
        if (c) {
          if (c >= -30) {
            setCredits(c);
            setPlan(res.data.plan);
            //alert("plan is" + res.data.plan);
          }
        }
      });
  }

  return (
    <>
      <Toaster />
      <CommonDrawer />

      <div
        //className="App"
        style={{
          //backgroundColor: "#FFFFFF",
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          // justifyContent: "space-evenly",
        }}
      >
        <AppBar
          position="static"
          style={{
            backgroundColor: "#000000",
            height: "7vh",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            //self aligns to the top
            alignSelf: "flex-start",
          }}
        >
          <Typography
            variant="h6"
            style={{
              flexGrow: 1,
              //bold
              fontWeight: 600,
              paddingLeft: "20px",
            }}
          >
            Helicity.ai✨ - Beta Block Editor
          </Typography>

          <Toolbar>
            <Typography
              variant="h6"
              style={{
                flexGrow: 1,
                //bold
                fontWeight: 600,
                paddingRight: "4vw",
              }}
            >
              {credits}✨
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => {
                if (!playClicked) {
                  requestCreateGame();
                  //set is playing to true
                  if (credits >= 2) {
                    setPlayClicked(true);
                    //toast insufficent credits
                  } else {
                    toast.error(
                      "Insufficient Credits, please upgrade your plan!"
                    );
                    //alrt asking open old url?
                    if (
                      window.confirm(
                        "You have insufficient credits, would you like to open your old game?"
                      )
                    ) {
                      //open the old url
                      window.open(
                        "https://storage.googleapis.com/ai-game-maker.appspot.com/public/" +
                          localStorage.getItem("uuid") +
                          ".html",
                        "_blank"
                      );
                    }
                  }
                }
              }}
            >
              {playClicked ? (
                //loading icon pink
                <CircularProgress
                  style={{
                    color: "#FF00FF",
                    //make it smaller
                    width: "30px",
                    height: "30px",
                  }}
                />
              ) : (
                <PlayArrowIcon
                  style={{
                    //make it smaller
                    width: "30px",
                    height: "30px",
                  }}
                />
              )}
            </IconButton>

            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => {
                //log out
                localStorage.removeItem("uuid");
                //go to login page
                window.location.href = "/";
              }}
            >
              <PersonIcon
                style={{
                  //make it smaller
                  width: "30px",
                  height: "30px",
                }}
              />
            </IconButton>

            <Button
              variant="contained"
              color="secondary"
              style={{
                //bg color is a gradient of dark green and dark
                background:
                  "linear-gradient(90deg, rgba(0,255,0,1) 0%, rgba(0,255,0,1) 50%, rgba(0,255,0,1) 100%)",
                height: "40px",
                //bold font
                fontWeight: 600,
                //black text
                color: "#000000",
              }}
              onClick={() => {
                //make this button invisible for 3 seconds
                setPortalClicked(true);
                //call firebase v1 firebase function createPortalSession
                // Get a reference to the Cloud Function
                const URL =
                  "https://us-central1-ai-game-maker.cloudfunctions.net/api/CreatePortalSession";
                axios
                  .post(URL, {
                    uuid: localStorage.getItem("uuid"),
                  })
                  .then((res) => {
                    //open the url in a new tab
                    setPortalClicked(false);
                    window.open(res.data.url, "_blank");
                  })
                  .catch((err) => {
                    alert("Error creating portal session");
                  });
              }}
            >
              <IconButton edge="start" color="inherit" aria-label="menu">
                {!portalClicked ? (
                  <AttachMoneyIcon
                    style={{
                      //make it smaller
                      width: "20px",
                      height: "20px",
                    }}
                  />
                ) : (
                  //circular progress bar
                  <CircularProgress
                    style={{
                      color: "#FF00FF",
                      //make it smaller
                      width: "30px",
                      height: "30px",
                    }}
                  />
                )}
              </IconButton>
              {plan == 0 ? "Free" : "Pro🔥"}
            </Button>
          </Toolbar>
        </AppBar>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "80%",
            height: "80vh",
            alignSelf: "center",
            backgroundColor: "#1E1E1E",
            color: "#FFF",
            borderRadius: "10px",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
            padding: "20px",
            marginTop: "30px",
          }}
        >
          <Blockly code={code} setCode={setCode} />
          {/* <TextField
            id="outlined-multiline-static"
            label="Code"
            multiline
            rows={20}
            variant="outlined"
            style={{
              width: "50%",
              height: "80%",
              backgroundColor: "#ffffff",
              color: "#FFF",
              borderRadius: "10px",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
              marginLeft: "20px",
              // marginTop: "30px",
              padding: "20px",
            }}
            value={code}
          ></TextField> */}

          <Editor
            height="80%"
            width="50%"
            theme="vs-dark"
            language="javascript"
            value={code}
            options={{
              readOnly: true,
              minimap: {
                enabled: false,
              },
            }}
          />
        </div>
      </div>
    </>
  );
}

export default GIDE;
