import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import Editor from "@monaco-editor/react";
import { editor } from "monaco-editor";
import {
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  AppBar,
  IconButton,
  Toolbar,
  Chip,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import axios from "axios";
import PersonIcon from "@material-ui/icons/Person";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./App.css";
//import common drawer js from same folder
import CommonDrawer from "./CommonDrawer.js";
//navigate
import { useNavigate } from "react-router-dom";
//import firebase
import firebase from "firebase/compat/app";
import { Toaster, toast } from "react-hot-toast";
//import firebase rtdb
import "firebase/compat/database";
import { Bar } from "react-chartjs-2";

const firebaseConfig = {
  apiKey: "AIzaSyCDM7KoL4QPJX1gln60thnslUukA4_Nd-U",
  authDomain: "ai-game-maker.firebaseapp.com",
  projectId: "ai-game-maker",
  storageBucket: "ai-game-maker.appspot.com",
  messagingSenderId: "100852636069",
  appId: "1:100852636069:web:d36e2204a6e62cd15fb04c",
  measurementId: "G-0P6M2SNKKW",
  //rtdb
  databaseURL: "https://ai-game-maker-default-rtdb.firebaseio.com/",
};
var db;

function Projects() {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
    const db1 = firebase.database();
    db = db1;
  } else {
    firebase.app(); // if already initialized, use that one
    const db1 = firebase.database();
    db = db1;
  }
  const navigate = useNavigate();
  if (localStorage.getItem("uuid") === null) {
    navigate("/");
  }

  const auth = firebase.auth();

  return (
    <>
      <Toaster />
      <CommonDrawer />

      <div
        //className="App"
        style={{
          //backgroundColor: "#FFFFFF",
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          // justifyContent: "space-evenly",
        }}
      >
        <AppBar
          position="static"
          style={{
            backgroundColor: "#000000",
            height: "7vh",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            //self aligns to the top
            alignSelf: "flex-start",
          }}
        >
          <Typography
            variant="h6"
            style={{
              flexGrow: 1,
              //bold
              fontWeight: 600,
              paddingLeft: "20px",
            }}
          >
            Helicity.ai✨ - My Analytics📈
          </Typography>

          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => {
                //set firebase auth state to null
                auth.signOut();

                //rmeove uuid from local storage
                localStorage.removeItem("uuid");
                //redirect to login page with react router navigate

                navigate("/");
              }}
            >
              <PersonIcon
                style={{
                  //make it smaller
                  width: "30px",
                  height: "30px",
                }}
              />
            </IconButton>
          </Toolbar>
        </AppBar>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "80%",
            height: "80vh",
            alignSelf: "center",
            backgroundColor: "#1E1E1E",
            color: "#FFF",
            borderRadius: "10px",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
            padding: "20px",
            marginTop: "30px",
          }}
        >
          <AnalyticsView />
        </div>
      </div>
    </>
  );
}

const AnalyticsView = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const url =
      "https://us-central1-ai-game-maker.cloudfunctions.net/api/analytics/42aef1d4-51a6-4f31-87a4-53b3c2c3f2aa";

    fetch(url)
      .then((response) => {
        console.log(response);

        response.json();
      })
      .then((analyticsData) => {
        // Process the analyticsData to create a format suitable for Chart.js
        const chartData = {
          labels: ["Game Start", "Game End"],
          datasets: [
            {
              label: "Number of Events",
              data: [
                analyticsData.game_start_count,
                analyticsData.game_end_count,
              ],
              // Other Chart.js options go here
            },
          ],
        };

        setData(chartData);
      })
      .catch((error) => {
        console.error("Error fetching analytics data:");
        console.error(error);
        alert(error);
      });
  }, []);

  return <div>{data ? <Bar data={data} /> : <p>Loading...</p>}</div>;
};

export default Projects;
