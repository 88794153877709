import React, { useEffect, useRef, useState } from "react";

function MyBlocklyComponent({ code, setCode }) {
  // Create a ref to store the script element
  const scriptRef = useRef(null);

  // Create a ref to store the workspace
  const workspaceRef = useRef(null);

  // Create a state variable to track the loading status of Blockly
  const [isLoaded, setIsLoaded] = useState(false);

  // Define a toolbox XML string
  const toolboxConfiguration = {
    kind: "categoryToolbox",
    contents: [
      {
        kind: "label",
        text: "Custom Toolbox",
        custom: "toolbox-label",
        colour: "darkslategrey",
      },
      {
        kind: "category",
        cssIcon: "customIcon fa fa-cog",
        name: "Logic",
        categorystyle: "logic_category",
        contents: [
          {
            kind: "block",
            type: "controls_if",
          },
          {
            kind: "block",
            type: "logic_compare",
          },
          {
            kind: "block",
            type: "logic_operation",
          },
          {
            kind: "block",
            type: "logic_negate",
          },
          {
            kind: "block",
            type: "logic_boolean",
          },
          {
            kind: "block",
            type: "logic_null",
            disabled: true,
          },
          {
            kind: "block",
            type: "logic_ternary",
          },
        ],
      },
      {
        kind: "category",
        name: "Loops",
        categorystyle: "loop_category",
        contents: [
          {
            kind: "block",
            type: "controls_repeat_ext",
            values: {
              TIMES: {
                kind: "block",
                type: "math_number",
                fields: {
                  NUM: "10",
                },
              },
            },
          },
          {
            kind: "block",
            type: "controls_repeat",
            disabled: true,
          },
          {
            kind: "block",
            type: "controls_whileUntil",
          },
          {
            kind: "block",
            type: "controls_for",
            values: {
              FROM: {
                kind: "block",
                type: "math_number",
                fields: {
                  NUM: "1",
                },
              },
              TO: {
                kind: "block",
                type: "math_number",
                fields: {
                  NUM: "10",
                },
              },
              BY: {
                kind: "block",
                type: "math_number",
                fields: {
                  NUM: "1",
                },
              },
            },
          },
          {
            kind: "block",
            type: "controls_forEach",
          },
          {
            kind: "block",
            type: "controls_flow_statements",
          },
        ],
      },
      {
        kind: "category",
        name: "Math",
        categorystyle: "math_category",
        contents: [
          {
            kind: "block",
            type: "math_number",
            gap: 32,
            fields: {
              NUM: "123",
            },
          },
          {
            kind: "block",
            type: "math_arithmetic",
            values: {
              A: {
                kind: "block",
                type: "math_number",
                fields: {
                  NUM: "1",
                },
              },
              B: {
                kind: "block",
                type: "math_number",
                fields: {
                  NUM: "1",
                },
              },
            },
          },
          {
            kind: "block",
            type: "math_single",
            values: {
              NUM: {
                kind: "block",
                type: "math_number",
                fields: {
                  NUM: "9",
                },
              },
            },
          },
          {
            kind: "block",
            type: "math_trig",
            values: {
              NUM: {
                kind: "block",
                type: "math_number",
                fields: {
                  NUM: "45",
                },
              },
            },
          },
          {
            kind: "block",
            type: "math_constant",
          },
          {
            kind: "block",
            type: "math_number_property",
            values: {
              NUMBER_TO_CHECK: {
                kind: "block",
                type: "math_number",
                fields: {
                  NUM: "0",
                },
              },
            },
          },
          {
            kind: "block",
            type: "math_round",
            values: {
              NUM: {
                kind: "block",
                type: "math_number",
                fields: {
                  NUM: "3.1",
                },
              },
            },
          },
          {
            kind: "block",
            type: "math_on_list",
          },
          {
            kind: "block",
            type: "math_modulo",
            values: {
              DIVIDEND: {
                kind: "block",
                type: "math_number",
                fields: {
                  NUM: "64",
                },
              },
              DIVISOR: {
                kind: "block",
                type: "math_number",
                fields: {
                  NUM: "10",
                },
              },
            },
          },
          {
            kind: "block",
            type: "math_constrain",
            values: {
              VALUE: {
                kind: "block",
                type: "math_number",
                fields: {
                  NUM: "50",
                },
              },
              LOW: {
                kind: "block",
                type: "math_number",
                fields: {
                  NUM: "1",
                },
              },
              HIGH: {
                kind: "block",
                type: "math_number",
                fields: {
                  NUM: "100",
                },
              },
            },
          },
          {
            kind: "block",
            type: "math_random_int",
            values: {
              FROM: {
                kind: "block",
                type: "math_number",
                fields: {
                  NUM: "1",
                },
              },
              TO: {
                kind: "block",
                type: "math_number",
                fields: {
                  NUM: "100",
                },
              },
            },
          },
          {
            kind: "block",
            type: "math_random_float",
          },
          {
            kind: "block",
            type: "math_atan2",
            values: {
              X: {
                kind: "block",
                type: "math_number",
                fields: {
                  NUM: "1",
                },
              },
              Y: {
                kind: "block",
                type: "math_number",
                fields: {
                  NUM: "1",
                },
              },
            },
          },
        ],
      },
      {
        kind: "category",
        name: "Text",
        categorystyle: "text_category",
        contents: [
          {
            kind: "block",
            type: "text",
          },
          {
            kind: "block",
            type: "text_multiline",
          },
          {
            kind: "block",
            type: "text_join",
          },
          {
            kind: "block",
            type: "text_append",
            values: {
              TEXT: {
                kind: "block",
                type: "text",
              },
            },
          },
          {
            kind: "block",
            type: "text_length",
            values: {
              VALUE: {
                kind: "block",
                type: "text",
                fields: {
                  TEXT: "abc",
                },
              },
            },
          },
          {
            kind: "block",
            type: "text_isEmpty",
            values: {
              VALUE: {
                kind: "block",
                type: "text",
                fields: {
                  TEXT: "",
                },
              },
            },
          },
          {
            kind: "block",
            type: "text_indexOf",
            values: {
              VALUE: {
                kind: "block",
                type: "variables_get",
                fields: {
                  VAR: "text",
                },
              },
              FIND: {
                kind: "block",
                type: "text",
                fields: {
                  TEXT: "abc",
                },
              },
            },
          },
          {
            kind: "block",
            type: "text_charAt",
            values: {
              VALUE: {
                kind: "block",
                type: "variables_get",
                fields: {
                  VAR: "text",
                },
              },
            },
          },
          {
            kind: "block",
            type: "text_getSubstring",
            values: {
              STRING: {
                kind: "block",
                type: "variables_get",
                fields: {
                  VAR: "text",
                },
              },
            },
          },
          {
            kind: "block",
            type: "text_changeCase",
            values: {
              TEXT: {
                kind: "block",
                type: "text",
                fields: {
                  TEXT: "abc",
                },
              },
            },
          },
          {
            kind: "block",
            type: "text_trim",
            values: {
              TEXT: {
                kind: "block",
                type: "text",
                fields: {
                  TEXT: "abc",
                },
              },
            },
          },
          {
            kind: "block",
            type: "text_count",
            values: {
              SUB: {
                kind: "block",
                type: "text",
              },
              TEXT: {
                kind: "block",
                type: "text",
              },
            },
          },
          {
            kind: "block",
            type: "text_replace",
            values: {
              FROM: {
                kind: "block",
                type: "text",
              },
              TO: {
                kind: "block",
                type: "text",
              },
              TEXT: {
                kind: "block",
                type: "text",
              },
            },
          },
          {
            kind: "block",
            type: "text_reverse",
            values: {
              TEXT: {
                kind: "block",
                type: "text",
              },
            },
          },
        ],
      },
      {
        kind: "category",
        name: "Lists",
        categorystyle: "list_category",
        //light blue
        contents: [
          {
            kind: "block",
            type: "lists_create_with",
            mutation: {
              items: "0",
            },
          },
          {
            kind: "block",
            type: "lists_create_with",
          },
          {
            kind: "block",
            type: "lists_repeat",
            values: {
              NUM: {
                kind: "block",
                type: "math_number",
                fields: {
                  NUM: "5",
                },
              },
            },
          },
          {
            kind: "block",
            type: "lists_length",
          },
          {
            kind: "block",
            type: "lists_isEmpty",
          },
          {
            kind: "block",
            type: "lists_indexOf",
            values: {
              VALUE: {
                kind: "block",
                type: "variables_get",
                fields: {
                  VAR: "list",
                },
              },
            },
          },
          {
            kind: "block",
            type: "lists_getIndex",
            values: {
              VALUE: {
                kind: "block",
                type: "variables_get",
                fields: {
                  VAR: "list",
                },
              },
            },
          },
          {
            kind: "block",
            type: "lists_setIndex",
            values: {
              LIST: {
                kind: "block",
                type: "variables_get",
                fields: {
                  VAR: "list",
                },
              },
            },
          },
          {
            kind: "block",
            type: "lists_getSublist",
            values: {
              LIST: {
                kind: "block",
                type: "variables_get",
                fields: {
                  VAR: "list",
                },
              },
            },
          },
          {
            kind: "block",
            type: "lists_split",
            values: {
              DELIM: {
                kind: "block",
                type: "text",
                fields: {
                  TEXT: ",",
                },
              },
            },
          },
          {
            kind: "block",
            type: "lists_sort",
          },
          {
            kind: "block",
            type: "lists_reverse",
          },
        ],
      },
      {
        kind: "category",
        name: "Input Controls",
        colour: 120,
        contents: [
          {
            kind: "block",
            type: "input_w",
          },
          {
            kind: "block",
            type: "input_a",
          },
          {
            kind: "block",
            type: "input_s",
          },
          {
            kind: "block",
            type: "input_d",
          },
          {
            kind: "block",
            type: "input_up",
          },
          {
            kind: "block",
            type: "input_down",
          },
          {
            kind: "block",
            type: "input_left",
          },
          {
            kind: "block",
            type: "input_right",
          },
          {
            kind: "block",
            type: "input_space",
          },
          {
            kind: "block",
            type: "input_shift",
          },
          {
            kind: "block",
            type: "input_mouse_left",
          },
          {
            kind: "block",
            type: "input_mouse_right",
          },
        ],
      },

      {
        kind: "category",
        name: "Helicity",
        categorystyle: "helicity_category",
        contents: [
          {
            kind: "block",
            style: "helicity_blocks",
            type: "game_starts",
            message0: "When Game Starts",
          },

          {
            kind: "block",
            style: "helicity_blocks",

            type: "custom_game_loop",
            message0: "Game Loop",
          },
          {
            kind: "block",
            type: "set_game_background_color",
            inputsInline: true,
            message0: "set game background color to %1",
            args0: [
              {
                type: "input_value",
                name: "COLOR",
                check: "Colour",
              },
            ],

            colour: 210,
            tooltip: "Set the background color of the game.",
            helpUrl: "",
          },
          {
            kind: "block",
            style: "helicity_blocks",

            type: "set_camera_type",
            values: {
              VAR: {
                kind: "field_variable",
                name: "VAR",
                variable: "item",
              },
              TYPE: {
                kind: "field_dropdown",
                name: "TYPE",
                options: [
                  ["Free", "free"],
                  ["Universal", "universal"],
                  ["Isometric", "isometric"],
                  ["Top", "top"],
                  ["Follow", "follow"],
                  ["Arc", "arc"],
                ],
              },
            },
          },
          {
            kind: "block",
            style: "helicity_blocks",

            type: "set_character_controller",
            values: {
              VAR: {
                kind: "field_variable",
                name: "VAR",
                variable: "item",
              },
              TYPE: {
                kind: "field_dropdown",
                name: "TYPE",
                options: [
                  ["First person", "firstperson"],
                  ["Third person", "thirdperson"],
                  ["Isometric", "isometric"],
                  ["Top", "top"],
                ],
              },
            },
          },
          {
            kind: "block",
            type: "scene",
            //style the color of the block
            style: "helicity_blocks",
          },

          {
            kind: "label",
            text: "Input/Output:",
            "web-class": "ioLabel",
          },
          {
            kind: "block",
            type: "text_print",
            values: {
              TEXT: {
                kind: "block",
                type: "text",
                fields: {
                  TEXT: "abc",
                },
              },
            },
          },
          {
            kind: "block",
            type: "text_prompt_ext",
            values: {
              TEXT: {
                kind: "block",
                type: "text",
                fields: {
                  TEXT: "abc",
                },
              },
            },
          },
          // Add other game object blocks here
        ],
      },
      {
        kind: "category",
        name: "Game Objects",
        colour: 210,
        contents: [
          {
            kind: "block",
            type: "create_box",
            fields: {
              NAME: "default name",
              WIDTH: 10,
              HEIGHT: 10,
              DEPTH: 10,
            },
          },
          {
            kind: "block",
            type: "set_position",
            values: {
              VAR: {
                kind: "field_variable",
                name: "VAR",
                variable: "item",
              },
              X: {
                kind: "block",
                type: "math_number",
                fields: { NUM: 1 },
              },
              Y: {
                kind: "block",
                type: "math_number",
                fields: { NUM: 1 },
              },
              Z: {
                kind: "block",
                type: "math_number",
                fields: { NUM: 1 },
              },
            },
          },
          {
            kind: "block",
            type: "get_x",
            values: {
              VAR: {
                kind: "field_variable",
                name: "VAR",
                variable: "item",
              },
            },
          },
          {
            kind: "block",
            type: "get_y",
            values: {
              VAR: {
                kind: "field_variable",
                name: "VAR",
                variable: "item",
              },
            },
          },
          {
            kind: "block",
            type: "get_z",
            values: {
              VAR: {
                kind: "field_variable",
                name: "VAR",
                variable: "item",
              },
            },
          },
          {
            kind: "block",
            type: "set_rotation",
            values: {
              VAR: {
                kind: "field_variable",
                name: "VAR",
                variable: "item",
              },
              X: {
                kind: "block",
                type: "math_number",
                fields: {
                  NUM: 0,
                },
              },
              Y: {
                kind: "block",
                type: "math_number",
                fields: {
                  NUM: 0,
                },
              },
              Z: {
                kind: "block",
                type: "math_number",
                fields: {
                  NUM: 0,
                },
              },
            },
          },
          {
            kind: "block",
            type: "get_rotation_alpha",
            values: {
              VAR: {
                kind: "field_variable",
                name: "VAR",
                variable: "item",
              },
            },
          },
          {
            kind: "block",
            type: "get_rotation_beta",
            values: {
              VAR: {
                kind: "field_variable",
                name: "VAR",
                variable: "item",
              },
            },
          },
          {
            kind: "block",
            type: "get_rotation_gamma",
            values: {
              VAR: {
                kind: "field_variable",
                name: "VAR",
                variable: "item",
              },
            },
          },

          {
            kind: "block",
            type: "set_color",
            values: {
              VAR: {
                kind: "field_variable",
                name: "VAR",
                variable: "item",
              },
              COLOR: {
                kind: "block",
                type: "colour_picker",
                fields: {
                  COLOUR: "#ff0000",
                },
              },
            },
          },
          {
            kind: "block",
            type: "gameobject_set_texture",
            message0: "for object: %1 set texture: %2",
            args0: [
              {
                type: "field_variable",
                name: "VAR",
                variable: "item",
              },
              {
                type: "field_input",
                name: "TEXTURE",
                text: "somewebsite.com/texture.png",
              },
            ],
            previousStatement: null,
            nextStatement: null,
            colour: 210,
            tooltip: "Set a texture for the game object.",
            helpUrl: "",
          },
        ],
      },

      {
        kind: "category",
        name: "Colour",
        categorystyle: "colour_category",
        contents: [
          {
            kind: "block",
            type: "colour_picker",
          },
          {
            kind: "block",
            type: "colour_random",
          },
          {
            kind: "block",
            type: "colour_rgb",
            values: {
              RED: {
                kind: "block",
                type: "math_number",
                fields: {
                  NUM: "100",
                },
              },
              GREEN: {
                kind: "block",
                type: "math_number",
                fields: {
                  NUM: "50",
                },
              },
              BLUE: {
                kind: "block",
                type: "math_number",
                fields: {
                  NUM: "0",
                },
              },
            },
          },
          {
            kind: "block",
            type: "colour_blend",
            values: {
              COLOUR1: {
                kind: "block",
                type: "colour_picker",
                fields: {
                  COLOUR: "#ff0000",
                },
              },
              COLOUR2: {
                kind: "block",
                type: "colour_picker",
                fields: {
                  COLOUR: "#3333ff",
                },
              },
              RATIO: {
                kind: "block",
                type: "math_number",
                fields: {
                  NUM: "0.5",
                },
              },
            },
          },
        ],
      },
      {
        kind: "sep",
      },
      {
        kind: "category",
        name: "Variables",
        categorystyle: "variable_category",
        custom: "VARIABLE",
      },
      {
        kind: "category",
        name: "Functions",
        categorystyle: "procedure_category",
        custom: "PROCEDURE",
      },
    ],
  };

  // Import the Blockly script from the CDN
  useEffect(() => {
    // Create a script element
    const script = document.createElement("script");

    // Set the src attribute to the unpkg URL
    script.src = "https://unpkg.com/blockly";

    // Append the script element to the document head
    document.head.appendChild(script);

    // Save the script element in the ref
    scriptRef.current = script;

    // Set a callback function for when the script is loaded
    script.onload = () => {
      // Set the loading status to true
      setIsLoaded(true);
    };

    // Remove the script element when the component unmounts
    return () => {
      document.head.removeChild(scriptRef.current);
    };
  }, []);

  // Inject Blockly into the div element
  useEffect(() => {
    //add that script
    const script2 = document.createElement("script");
    script2.src = "https://unpkg.com/blockly/javascript_compressed.js";
    document.head.appendChild(script2);
    script2.onload = () => {
      // Check if Blockly is loaded
      if (isLoaded) {
        const Blockly = window.Blockly;
        const javascriptGenerator = window.Blockly.JavaScript;

        //Now we add custom blocks defintions
        var customTheme = window.Blockly.Theme.defineTheme("customTheme", {
          base: Blockly.Themes.Classic,
          blockStyles: {
            list_blocks: {
              colourPrimary: "#87CEFA",
              colourSecondary: "#2F4F4F", // Dark green
              colourTertiary: "#B0C4DE", // Light steel blue
            },
            colour_blocks: {
              colourPrimary: "#a0a0a0", // Gray color
              colourSecondary: "#696969", // Dim gray
              colourTertiary: "#808080", // Gray
            },
            text_blocks: {
              colourPrimary: "#FF69B4",
              colourSecondary: "#8B008B", // Dark magenta
              colourTertiary: "#FFB6C1",
            },
            loop_blocks: {
              colourPrimary: "#DAA520", // Goldenrod
              colourSecondary: "#B8860B", // Dark goldenrod
              colourTertiary: "#FFD700", // Gold
            },
            helicity_blocks: {
              colourPrimary: "#FFA500", // Orange
              colourSecondary: "#FF8C00", // Dark orange
              colourTertiary: "#FFD700", // Gold
              //text colour blACK
              fontColour: "#000000",
            },
            logic_blocks: {
              //green
              colourPrimary: "#008000",
              //dark green
              colourSecondary: "#006400",
              //light green
              colourTertiary: "#00FF00",
            },
          },
          categoryStyles: {
            colour_category: {
              colour: "#a0a0a0", // Gray color
            },
            list_category: {
              colour: "#87CEFA",
            },
            text_category: {
              colour: "#FFB6C1",
            },
            loop_category: {
              colour: "#DAA520", // Goldenrod
            },
            helicity_category: {
              colour: "#FFA500", // Orange
            },
            logic_category: {
              colour: "#00FF00", // ltGreen
            },
          },
        });

        // Inject Blockly into the div element with the given options
        const workspace = window.Blockly.inject("blocklyDiv", {
          toolbox: toolboxConfiguration,
          scrollbars: true,
          horizontalLayout: false,
          toolboxPosition: "start",
          //add option to have custom theme
          theme: customTheme,

          trashcan: true,
          //add option to have zoom in and out
          zoom: {
            controls: true,
            wheel: true,
            startScale: 1.0,
            maxScale: 3,
            minScale: 0.3,
            scaleSpeed: 1.2,
          },
        });

        //init the code generator
        javascriptGenerator.init(workspace);

        // Save the workspace in the ref
        workspaceRef.current = workspace;

        //scene block
        Blockly.Blocks["scene"] = {
          init: function () {
            this.appendDummyInput().appendField("scene");
            this.setOutput(true, "Scene");
            this.setColour("#FFA000");

            this.setTooltip("Refers to the current scene in the game engine.");
            this.setHelpUrl("");
            //set text colour
          },
        };

        Blockly.JavaScript.forBlock["scene"] = function (block) {
          // The generated code is a call to the getScene function in the game engine
          var code = "Engine.getScene()";
          // The scene block can be used in value inputs, so we return the code with ORDER_NONE
          return [code, Blockly.JavaScript.ORDER_NONE];
        };

        ///dictionary block

        //TODO

        //INPUT Category Blocks
        const keys = [
          "w",
          "a",
          "s",
          "d",
          "up",
          "down",
          "left",
          "right",
          "space",
          "shift",
          "mouse_left",
          "mouse_right",
        ];

        keys.forEach((key) => {
          Blockly.Blocks[`input_${key}`] = {
            init: function () {
              this.appendDummyInput().appendField(`${key} key pressed down`);
              this.setOutput(true, "Boolean");
              this.setColour(120);
              this.setTooltip(`Checks if the '${key}' key is pressed down`);
              this.setHelpUrl("");
            },
          };

          Blockly.JavaScript.forBlock[`input_${key}`] = function (block) {
            var code = `Input.getState().keys.${key}`;
            return [code, Blockly.JavaScript.ORDER_ATOMIC];
          };
        });

        //Game Object Category Blocks
        Blockly.Blocks["create_box"] = {
          init: function () {
            this.appendDummyInput()
              .appendField("Create box with name:")
              .appendField(new Blockly.FieldTextInput("default name"), "NAME");
            this.appendDummyInput()
              .appendField("width:")
              .appendField(new Blockly.FieldNumber(10), "WIDTH");
            this.appendDummyInput()
              .appendField("height:")
              .appendField(new Blockly.FieldNumber(10), "HEIGHT");
            this.appendDummyInput()
              .appendField("length:")
              .appendField(new Blockly.FieldNumber(10), "DEPTH");
            // this.appendValueInput("SCENE").appendField("in scene:");
            this.setOutput(true, null);
            this.setColour(210);
          },
        };
        Blockly.JavaScript.forBlock["create_box"] = function (block) {
          var text_name = block.getFieldValue("NAME");
          var number_width = block.getFieldValue("WIDTH");
          var number_height = block.getFieldValue("HEIGHT");
          var number_depth = block.getFieldValue("DEPTH");

          // Check if a scene block is connected, if not, default to "Engine.getScene()"
          var scene_code = "Engine.getScene()";

          // Generate JavaScript code to create a new box with the specified properties
          var code = `new GameObject("${text_name}", "box", { width: ${number_width}, height: ${number_height}, depth: ${number_depth} },  ${scene_code})`;

          return [code, Blockly.JavaScript.ORDER_ATOMIC];
        };

        ///set position block
        Blockly.Blocks["set_position"] = {
          init: function () {
            this.appendDummyInput()
              .appendField("for object:")
              .appendField(new Blockly.FieldVariable("item"), "VAR");
            this.appendDummyInput().appendField("set x:");
            this.appendValueInput("X").setCheck("Number");
            this.appendDummyInput().appendField("y:");
            this.appendValueInput("Y").setCheck("Number");
            this.appendDummyInput().appendField("z:");
            this.appendValueInput("Z").setCheck("Number");
            this.setPreviousStatement(true, null);
            this.setNextStatement(true, null);
            this.setColour(210);
            this.setTooltip("");
            this.setHelpUrl("");
          },
        };
        Blockly.JavaScript.forBlock["set_position"] = function (block) {
          var variable_id = block.getFieldValue("VAR");
          var variable_model = block.workspace.getVariableById(variable_id);
          var variable_name = variable_model.name;

          var value_x = Blockly.JavaScript.valueToCode(
            block,
            "X",
            Blockly.JavaScript.ORDER_ATOMIC
          );
          var value_y = Blockly.JavaScript.valueToCode(
            block,
            "Y",
            Blockly.JavaScript.ORDER_ATOMIC
          );
          var value_z = Blockly.JavaScript.valueToCode(
            block,
            "Z",
            Blockly.JavaScript.ORDER_ATOMIC
          );
          var code =
            variable_name +
            ".setPosition(" +
            value_x +
            ", " +
            value_y +
            ", " +
            value_z +
            ");\n";
          return code;
        };

        //get x
        Blockly.Blocks["get_x"] = {
          init: function () {
            this.appendDummyInput()
              .appendField("get x of object:")
              .appendField(new Blockly.FieldVariable("item"), "VAR");
            this.setOutput(true, "Number");
            this.setColour(210);
            this.setTooltip("");
            this.setHelpUrl("");
          },
        };
        Blockly.JavaScript.forBlock["get_x"] = function (block) {
          var variable_id = block.getFieldValue("VAR");
          var variable_model = block.workspace.getVariableById(variable_id);
          var variable_name = variable_model.name;

          var code = variable_name + ".getX()";
          return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
        };

        //get y
        Blockly.Blocks["get_y"] = {
          init: function () {
            this.appendDummyInput()
              .appendField("get y of object:")
              .appendField(new Blockly.FieldVariable("item"), "VAR");
            this.setOutput(true, "Number");
            this.setColour(210);
            this.setTooltip("");
            this.setHelpUrl("");
          },
        };
        Blockly.JavaScript.forBlock["get_y"] = function (block) {
          var variable_id = block.getFieldValue("VAR");
          var variable_model = block.workspace.getVariableById(variable_id);
          var variable_name = variable_model.name;

          var code = variable_name + ".getY()";
          return [code, Blockly.JavaScript.ORDER_NONE];
        };

        //get z
        Blockly.Blocks["get_z"] = {
          init: function () {
            this.appendDummyInput()

              .appendField("get z of object:")
              .appendField(new Blockly.FieldVariable("item"), "VAR");
            this.setOutput(true, "Number");
            this.setColour(210);
            this.setTooltip("");
            this.setHelpUrl("");
          },
        };
        Blockly.JavaScript.forBlock["get_z"] = function (block) {
          var variable_id = block.getFieldValue("VAR");
          var variable_model = block.workspace.getVariableById(variable_id);
          var variable_name = variable_model.name;

          var code = variable_name + ".getZ()";
          return [code, Blockly.JavaScript.ORDER_NONE];
        };

        // Set Rotation Block
        Blockly.Blocks["set_rotation"] = {
          init: function () {
            this.appendDummyInput()
              .appendField("for object:")
              .appendField(new Blockly.FieldVariable("item"), "VAR");
            this.appendDummyInput().appendField("set rotation alpha:");
            this.appendValueInput("X").setCheck("Number");
            this.appendDummyInput().appendField("beta:");
            this.appendValueInput("Y").setCheck("Number");
            this.appendDummyInput().appendField("gamma:");
            this.appendValueInput("Z").setCheck("Number");
            this.setPreviousStatement(true, null);
            this.setNextStatement(true, null);
            this.setColour(210);
            this.setTooltip("");
            this.setHelpUrl("");
          },
        };

        // Get Rotation Alpha Block
        Blockly.Blocks["get_rotation_alpha"] = {
          init: function () {
            this.appendDummyInput()
              .appendField("for object:")
              .appendField(new Blockly.FieldVariable("item"), "VAR");
            this.appendDummyInput().appendField("get rotation alpha");
            this.setOutput(true, "Number");
            this.setColour(210);
            this.setTooltip("");
            this.setHelpUrl("");
          },
        };

        // And similarly for get_rotation_beta and get_rotation_gamma
        Blockly.Blocks["get_rotation_beta"] = {
          init: function () {
            this.appendDummyInput()
              .appendField("for object:")
              .appendField(new Blockly.FieldVariable("item"), "VAR");
            this.appendDummyInput().appendField("get rotation beta");
            this.setOutput(true, "Number");
            this.setColour(210);
            this.setTooltip("");
            this.setHelpUrl("");
          },
        };

        Blockly.Blocks["get_rotation_gamma"] = {
          init: function () {
            this.appendDummyInput()
              .appendField("for object:")
              .appendField(new Blockly.FieldVariable("item"), "VAR");
            this.appendDummyInput().appendField("get rotation gamma");
            this.setOutput(true, "Number");
            this.setColour(210);
            this.setTooltip("");
            this.setHelpUrl("");
          },
        };

        Blockly.JavaScript["set_rotation"] = function (block) {
          var variable_id = block.getFieldValue("VAR");
          var variable_model = block.workspace.getVariableById(variable_id);
          var variable_name = variable_model.name;
          var value_x = Blockly.JavaScript.valueToCode(
            block,
            "X",
            Blockly.JavaScript.ORDER_ATOMIC
          );
          var value_y = Blockly.JavaScript.valueToCode(
            block,
            "Y",
            Blockly.JavaScript.ORDER_ATOMIC
          );
          var value_z = Blockly.JavaScript.valueToCode(
            block,
            "Z",
            Blockly.JavaScript.ORDER_ATOMIC
          );
          var code =
            variable_name +
            ".setRotation(" +
            value_x +
            ", " +
            value_y +
            ", " +
            value_z +
            ");\n";
          return code;
        };

        Blockly.JavaScript["get_rotation_alpha"] = function (block) {
          var variable_id = block.getFieldValue("VAR");
          var variable_model = block.workspace.getVariableById(variable_id);
          var variable_name = variable_model.name;
          var code = variable_name + ".getRotationAlpha()";
          return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
        };

        // And similarly for get_rotation_beta and get_rotation_gamma
        Blockly.JavaScript["get_rotation_beta"] = function (block) {
          var variable_id = block.getFieldValue("VAR");
          var variable_model = block.workspace.getVariableById(variable_id);
          var variable_name = variable_model.name;
          var code = variable_name + ".getRotationBeta()";
          return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
        };

        Blockly.JavaScript["get_rotation_gamma"] = function (block) {
          var variable_id = block.getFieldValue("VAR");
          var variable_model = block.workspace.getVariableById(variable_id);
          var variable_name = variable_model.name;
          var code = variable_name + ".getRotationGamma()";
          return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
        };

        //set color
        Blockly.Blocks["set_color"] = {
          init: function () {
            this.appendDummyInput()
              .appendField("for object:")
              .appendField(new Blockly.FieldVariable("item"), "VAR");
            this.appendDummyInput().appendField("set color:");
            this.appendValueInput("COLOR").setCheck("Colour");
            this.setPreviousStatement(true, null);
            this.setNextStatement(true, null);
            this.setColour(210);
            this.setTooltip("");
            this.setHelpUrl("");
          },
        };
        Blockly.JavaScript["set_color"] = function (block) {
          var variable_id = block.getFieldValue("VAR");
          var variable_model = block.workspace.getVariableById(variable_id);
          var variable_name = variable_model.name;
          var color_hex = Blockly.JavaScript.valueToCode(
            block,
            "COLOR",
            Blockly.JavaScript.ORDER_ATOMIC
          );

          // Remove the quotes and '#' from the color hex code
          color_hex = color_hex.replace(/['"#]/g, "");

          // Convert hex color to RGB
          var r = parseInt(color_hex.substring(0, 2), 16) / 255;
          var g = parseInt(color_hex.substring(2, 4), 16) / 255;
          var b = parseInt(color_hex.substring(4, 6), 16) / 255;

          var code =
            variable_name + ".setColor(" + r + ", " + g + ", " + b + ");\n";
          return code;
        };

        //set camera type
        Blockly.Blocks["set_camera_type"] = {
          init: function () {
            this.appendDummyInput()
              .appendField("Set camera type to ")
              .appendField(
                new Blockly.FieldDropdown([
                  ["Free", "free"],
                  ["Universal", "universal"],
                  ["Isometric", "isometric"],
                  ["Top", "top"],
                  ["Follow", "follow"],
                  ["Arc", "arc"],
                ]),
                "TYPE"
              );
            this.appendDummyInput()
              .appendField(" and if following, follow object:")
              .appendField(new Blockly.FieldVariable("item"), "VAR");

            this.setPreviousStatement(true, null);
            this.setNextStatement(true, null);

            this.setTooltip("");
            this.setHelpUrl("");
            this.setColour("#FFA000");
          },
        };

        Blockly.JavaScript.forBlock["set_camera_type"] = function (block) {
          var variable_id = block.getFieldValue("VAR");
          var variable_model = block.workspace.getVariableById(variable_id);
          var variable_name = variable_model.name;
          var dropdown_type = block.getFieldValue("TYPE");
          var code =
            'Engine.setCameraType("' +
            dropdown_type +
            '", ' +
            variable_name +
            ");\n";
          return code;
        };

        //set character controller
        Blockly.Blocks["set_character_controller"] = {
          init: function () {
            this.appendDummyInput()
              .appendField("Set character controller for")
              .appendField(new Blockly.FieldVariable("item"), "VAR");
            this.appendDummyInput()
              .appendField("as")
              .appendField(
                new Blockly.FieldDropdown([
                  ["First person", "firstperson"],
                  ["Third person", "thirdperson"],
                  ["Isometric", "isometric"],
                  ["Top", "top"],
                ]),
                "TYPE"
              );
            this.setPreviousStatement(true, null);
            this.setNextStatement(true, null);
            this.setColour("#FFA000");
            this.setTooltip("Set the character controller type");
          },
        };

        Blockly.JavaScript["set_character_controller"] = function (block) {
          var variable_id = block.getFieldValue("VAR");
          var variable_model = block.workspace.getVariableById(variable_id);
          var variable_name = variable_model.name;
          var dropdown_type = block.getFieldValue("TYPE");
          var code =
            "Engine.setCharacterController(" +
            variable_name +
            ', "' +
            dropdown_type +
            '");\n';
          return code;
        };

        //Game Loop
        Blockly.Blocks["custom_game_loop"] = {
          init: function () {
            this.appendDummyInput().appendField("Game Loop");
            this.appendStatementInput("DO").setCheck(null);
            this.setInputsInline(true);
            this.setColour("#FFA000");
            this.setTooltip("Game loop where custom game logic goes");
            this.setHelpUrl("");
          },
        };
        Blockly.JavaScript["custom_game_loop"] = function (block) {
          var statements_do = Blockly.JavaScript.statementToCode(block, "DO");
          var code =
            "const customGameLoop = () => {\n" +
            statements_do +
            "};\nEngine.gameLoop(customGameLoop);";
          return code;
        };

        //When Game starts
        Blockly.Blocks["game_starts"] = {
          init: function () {
            this.appendDummyInput().appendField("When Game Starts");
            this.appendStatementInput("STATEMENTS").setCheck(null);

            this.setTooltip(
              "Code in this block will run when the game starts."
            );
            this.setHelpUrl("");
            this.setColour("#FFA000");
          },
        };
        Blockly.JavaScript["game_starts"] = function (block) {
          var statements = Blockly.JavaScript.statementToCode(
            block,
            "STATEMENTS"
          );
          return `//When your Game Starts\n` + statements;
        };

        //set gameobject texture
        Blockly.Blocks["gameobject_set_texture"] = {
          init: function () {
            this.appendDummyInput()
              .appendField("for object:")
              .appendField(new Blockly.FieldVariable("item"), "VAR");
            this.appendDummyInput()
              .appendField("set texture:")
              .appendField(
                new Blockly.FieldTextInput("path/to/texture.png"),
                "TEXTURE"
              );
            this.setPreviousStatement(true, null);
            this.setNextStatement(true, null);
            this.setColour(210);
            this.setTooltip("Set a texture for the game object.");
            this.setHelpUrl("");
          },
        };

        Blockly.JavaScript["gameobject_set_texture"] = function (block) {
          var variable_id = block.getFieldValue("VAR");
          var variable_model = block.workspace.getVariableById(variable_id);
          var variable_name = variable_model.name;
          var texture_path = block.getFieldValue("TEXTURE");

          var code = variable_name + '.setTexture("' + texture_path + '");\n';
          return code;
        };

        //// set game background color
        Blockly.Blocks["set_game_background_color"] = {
          init: function () {
            this.appendDummyInput().appendField("set game background color to");
            this.appendValueInput("COLOR").setCheck("Colour");
            this.setPreviousStatement(true, null);
            this.setNextStatement(true, null);
            this.setColour("#FFA000");
            this.setTooltip("Set the background color of the game.");
            this.setHelpUrl("");
          },
        };

        Blockly.JavaScript["set_game_background_color"] = function (block) {
          var color_hex = Blockly.JavaScript.valueToCode(
            block,
            "COLOR",
            Blockly.JavaScript.ORDER_ATOMIC
          );

          // Remove the quotes from the color hex code
          color_hex = color_hex.replace(/['"#]/g, "");

          // Convert hex color to RGB
          var r = parseInt(color_hex.substring(0, 2), 16) / 255;
          var g = parseInt(color_hex.substring(2, 4), 16) / 255;
          var b = parseInt(color_hex.substring(4, 6), 16) / 255;

          var code =
            "Engine.setSkyboxColor(" + r + ", " + g + ", " + b + ");\n";
          return code;
        };

        //AFTER DEFINING ALL BLOCKS, LOAD THE EXISTING WORKSPACE
        var wsFromLS = localStorage.getItem("blocklyWorkspace");
        if (wsFromLS) {
          //now convert back to json
          wsFromLS = JSON.parse(wsFromLS);
          Blockly.serialization.workspaces.load(wsFromLS, workspace);
        }

        function updateCode(event) {
          var jsCode = javascriptGenerator.workspaceToCode(workspace);
          updateJSCode(jsCode);
          var state = Blockly.serialization.workspaces.save(workspace);
          localStorage.setItem("blocklyWorkspace", JSON.stringify(state));
        }
        workspace.addChangeListener(updateCode);
      }
    };

    //add more blocks here
  }, [isLoaded]);

  function updateJSCode(jsCode) {
    setCode(jsCode);
  }

  return (
    <>
      {isLoaded && (
        <div id="blocklyDiv" style={{ height: "70h", width: "90%" }} />
      )}
    </>
  );
}

export default MyBlocklyComponent;
