"use client";
import Typography from "@material-ui/core/Typography";
import {
  CardContent,
  Card,
  TextField,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core";
import { useEffect, useState } from "react";

//import axios
//code icon
import CodeIcon from "@material-ui/icons/Code";
// editor icon

//tv icon
import TvIcon from "@material-ui/icons/Tv";
import GithubIcon from "@material-ui/icons/GitHub";
//image icon
import ImageIcon from "@material-ui/icons/Image";
import ArticleIcon from "@material-ui/icons/Assignment";

function CommonDrawer() {
  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    // Handle the selected option logic here
    //navigate to the page
    if (option === "main") {
      //navigae to ide
      window.location.href = "/projects";
    }
    if (option === "github") {
      window.location.href = "https://github.com/lil-shake-code/helicity.ai";
    }
    if (option === "assets") {
      window.location.href = "https://helicity.gitbook.io/docs/";
    }
  };
  return (
    <div>
      {" "}
      <Drawer
        variant="permanent"
        anchor="bottom"
        style={{
          width: "100px", // Adjust the width as needed
          flexShrink: 0,
        }}
        PaperProps={{
          style: {
            width: "100px", // Adjust the width as needed
            background: "transparent",
            border: "none",
          },
        }}
      >
        <div style={{ overflow: "auto" }}>
          <List>
            <ListItem
              button
              selected={selectedOption === "main"}
              onClick={() => handleOptionClick("main")}
              style={{ background: "transparent" }}
            >
              <ListItemIcon
                style={{
                  color: "white",
                }}
              >
                <CodeIcon />
              </ListItemIcon>
            </ListItem>
            <ListItem
              button
              selected={selectedOption === "github"}
              onClick={() => handleOptionClick("github")}
              style={{ background: "transparent" }}
            >
              <ListItemIcon
                style={{
                  color: "white",
                  height: "30px",
                  width: "30px",
                }}
              >
                <GithubIcon />
              </ListItemIcon>
            </ListItem>
            <ListItem
              button
              selected={selectedOption === "assets"}
              onClick={() => handleOptionClick("assets")}
              style={{ background: "transparent" }}
            >
              <ListItemIcon
                style={{
                  color: "white",
                  height: "30px",
                  width: "30px",
                }}
              >
                <ArticleIcon />
              </ListItemIcon>
            </ListItem>
          </List>
        </div>
      </Drawer>
    </div>
  );
}

export default CommonDrawer;
