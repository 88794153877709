import React, { useState, useEffect } from "react";
import { Stage, Layer, Image as KonvaImage, Transformer } from "react-konva";
import useImage from "use-image";
import {
  TextField,
  IconButton,
  Grid,
  Box,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import PubSub from "./pubsub.js";

const URLImage = ({ url }) => {
  const [image] = useImage(url);
  return <img src={url} alt="" style={{ maxWidth: "100%", height: "auto" }} />;
};

const CanvasImage = ({ asset, isSelected, onSelect, onChange }) => {
  const shapeRef = React.useRef();
  const trRef = React.useRef();
  const [image] = useImage(asset.url);

  useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  return (
    <>
      <KonvaImage
        onClick={onSelect}
        onTap={onSelect}
        ref={shapeRef}
        {...asset}
        image={image}
        draggable
        onDragEnd={(e) => {
          onChange({
            ...asset,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
        onTransformEnd={() => {
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();
          const rotation = node.rotation();

          // we will reset scale on transform end
          node.scaleX(1);
          node.scaleY(1);
          onChange({
            ...asset,
            x: node.x(),
            y: node.y(),
            rotation: rotation,
            width: Math.max(5, node.width() * scaleX),
            height: Math.max(node.height() * scaleY),
          });
        }}
      />
      {isSelected && <Transformer ref={trRef} />}
    </>
  );
};

function CanvasEditor({ setCanvasCode }) {
  const [assets, setAssets] = useState(
    JSON.parse(localStorage.getItem("assets")) || []
  );
  const [canvasState, setCanvasState] = useState([]);
  const [selectedId, selectShape] = useState(null);
  const [newAssetUrl, setNewAssetUrl] = useState("");
  const [newAssetName, setNewAssetName] = useState("");
  const [addAssetDialogOpen, setAddAssetDialogOpen] = useState(false);
  const [canvasBG, setCanvasBG] = useState(
    "https://opengameart.org/sites/default/files/bg_5.png"
  );

  function setCanvasStateFirstTime(canvasState) {
    setCanvasState(canvasState);

    var code = ``;
    //canvas state is an array of objects. for each object, we need to add a line of code to the code variable
    for (let i in canvasState) {
      code += `var ${canvasState[i].name} = new GameObject(${Math.floor(
        canvasState[i].x
      )}, ${Math.floor(canvasState[i].y)}, ${Math.floor(
        canvasState[i].width
      )}, ${Math.floor(canvasState[i].height)}, "${canvasState[i].name}", "${
        canvasState[i].url
      }" , ${Math.floor(canvasState[i].rotation)} );`;
      //new line
      code += `\n`;
    }
    //add the code for the bg
    code += `Renderer.setBackground("${canvasBG}");`;

    PubSub.publish("canvas.first-time-change", code);
  }
  //subscribe to canvasState.change event
  useEffect(() => {
    //FIRST TIME CANVAS CHANGE AFTER AI GEN
    PubSub.subscribe("canvas.change", (data) => {
      //just got the ai gen canvas from server

      var newCanvasState = JSON.parse(data);
      //find the element in newCanvasState that has element.name == background
      var backgroundElement = newCanvasState.find(
        (element) => element.name == "background" || element.id == "background"
      );
      if (backgroundElement) {
        setCanvasBG(backgroundElement.url);
      }

      //setting the canvas state to the one obtained from server
      setCanvasStateFirstTime(newCanvasState);
    });

    PubSub.subscribe("canvas.first-time-cloud-load", (data) => {
      if (typeof data !== "object") {
        data = [];
      }
      setCanvasState(data);
      //if data has object for bg then set canvasBG to its url
      var backgroundElement = data.find(
        (element) => element.name == "background" || element.id == "background"
      );
      if (backgroundElement) {
        setCanvasBG(backgroundElement.url);
      }
    });
  }, []);

  useEffect(() => {
    localStorage.setItem("assets", JSON.stringify(assets));
  }, [assets]);

  useEffect(() => {
    //set local storage canvas state
    localStorage.setItem("canvasState", JSON.stringify(canvasState));
    var code = ``;
    //canvas state is an array of objects. for each object, we need to add a line of code to the code variable
    for (let i in canvasState) {
      if (canvasState[i].name == "background") {
        continue;
      }
      code += `var ${canvasState[i].name} = new GameObject(${Math.floor(
        canvasState[i].x
      )}, ${Math.floor(canvasState[i].y)}, ${Math.floor(
        canvasState[i].width
      )}, ${Math.floor(canvasState[i].height)}, "${canvasState[i].name}", "${
        canvasState[i].url
      }" , ${Math.floor(canvasState[i].rotation)} );\n`;
    }
    //new line
    code += `\n`;
    //add the code for the bg
    code += `Renderer.setBackground("${canvasBG}");`;

    setCanvasCode(code);
  }, [canvasState]);

  //canvas bg use effect
  useEffect(() => {
    //traverse through canvasState and find the element with name == background. if found, update its url
    var index = canvasState.findIndex(
      (element) => element.name == "background"
    );
    if (index > -1) {
      var newCanvasState = [...canvasState];
      newCanvasState[index].url = canvasBG;
      setCanvasState(newCanvasState);
    } else {
      //add a new element to canvasState with name == background
      var newCanvasState = [...canvasState];
      newCanvasState.push({
        url: canvasBG,
        name: "background",
        id: "background",
        x: 0,
        y: 0,
        width: 500,
        height: 500,
        rotation: 0,
      });
      setCanvasState(newCanvasState);
    }
  }, [canvasBG]);

  const handleAddAsset = () => {
    if (newAssetUrl && newAssetName) {
      const newAsset = {
        url: newAssetUrl,
        name: newAssetName,
        id: `${newAssetName}_${Date.now()}`,
      };
      setAssets([...assets, newAsset]);
      setNewAssetUrl("");
      setNewAssetName("");
    }
  };
  function generateUniqueName(array, baseName) {
    let maxNumber = 0;
    for (let i = 0; i < array.length; i++) {
      const match = array[i].name.match(new RegExp(`^${baseName}(\\d*)$`));
      if (match) {
        const number = parseInt(match[1]);
        if (isNaN(number)) {
          maxNumber = Math.max(maxNumber, 1);
        } else {
          maxNumber = Math.max(maxNumber, number + 1);
        }
      }
    }
    return baseName + (maxNumber === 0 ? "" : maxNumber);
  }

  // Usage:

  const handleAddAssetToCanvas = (asset) => {
    var uName = generateUniqueName(canvasState, asset.name);
    const newCanvasAsset = {
      ...asset,
      x: 100,
      y: 100,
      width: 100,
      height: 100,
      rotation: 0,
      name: uName,
      id: uName,
    };
    setCanvasState([...canvasState, newCanvasAsset]);
    console.log(canvasState);
    setAddAssetDialogOpen(false);
  };

  const handleCanvasAssetUpdate = (updatedAsset) => {
    const index = canvasState.findIndex(
      (asset) => asset.id === updatedAsset.id
    );
    if (index > -1) {
      const updatedCanvasAssets = [...canvasState];
      updatedCanvasAssets[index] = updatedAsset;
      setCanvasState(updatedCanvasAssets);
    }
  };

  const handleDeleteAsset = (id) => {
    const updatedCanvasAssets = canvasState.filter((asset) => asset.id !== id);

    setCanvasState(updatedCanvasAssets);
  };

  const handleDeleteGlobalAsset = (id) => {
    const updatedGlobalAssets = assets.filter((asset) => asset.id !== id);
    setAssets(updatedGlobalAssets);
  };

  return (
    <div style={{}}>
      <Box p={3}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            style={{
              //center it all
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              style={{
                //bold
                fontWeight: "bold",
              }}
            >
              Beta AI-Integrated Canvas🖌️
            </Typography>
            <Stage
              width={500}
              height={500}
              style={{
                //purple outline
                border: "5px solid #9c27b0",
                borderRadius: "5px",
                height: "500px",
                width: "500px",
                background: "white",
                backgroundImage: `url(${canvasBG})`,
                scale: "0.9",
                //image fit cover
                backgroundSize: "cover",
              }}
            >
              <Layer>
                {canvasState.map((asset) => {
                  if (asset.name == "background") {
                    return null;
                  }
                  return (
                    <CanvasImage
                      key={asset.id}
                      asset={asset}
                      isSelected={asset.id === selectedId}
                      onSelect={() => {
                        selectShape(asset.id);
                      }}
                      onChange={handleCanvasAssetUpdate}
                    />
                  );
                })}
              </Layer>
            </Stage>
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              //blue border outline
              border: "5px solid #2196f3",

              //round corners
              borderRadius: "10px",
              padding: "10px",
              //margin top
              marginTop: "20px",
            }}
          >
            <Typography variant="h6">Canvas Assets</Typography>
            <div
              style={{
                //white bg
                background: "white",
                borderRadius: "10px",
                padding: "10px",
                //purple outline
                border: "5px solid #9c27b0",
              }}
            >
              <TextField
                label="Background Image URL"
                value={canvasBG}
                onChange={(e) => setCanvasBG(e.target.value)}
                style={{
                  // marginRight: "10px",
                  // padding: "10px",
                  //full width
                  width: "80%",
                  height: "50%",
                }}
                //contained
                variant="outlined"
              />
            </div>

            <IconButton onClick={() => setAddAssetDialogOpen(true)}>
              <AddIcon
                style={{
                  color: "white",
                  marginBottom: "10px",
                }}
              />
            </IconButton>
            <Grid container spacing={3}>
              {canvasState.map((asset, index) =>
                //if asset.name == background, then dont show it
                asset.name == "background" ? null : (
                  <Grid item xs={4} key={asset.id}>
                    <Card
                      style={{
                        width: "100px",

                        //purple outline
                        border: "5px solid #9c27b0",
                        //round corners
                        borderRadius: "10px",
                      }}
                    >
                      <CardContent
                        style={{
                          //center it all
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <URLImage
                          url={asset.url}
                          style={{
                            height: "100px",
                            width: "100px",
                            //object fit contined
                            objectFit: "contain",
                          }}
                        />
                        <Typography
                          style={{
                            color: "#9c27b0",
                            //bold
                            fontWeight: "bold",
                            //SELF ALIGN CENTER
                            textAlign: "center",
                          }}
                        >
                          {asset.name}
                        </Typography>
                        <IconButton onClick={() => handleDeleteAsset(asset.id)}>
                          <DeleteIcon
                            style={{
                              color: "#9c27b0",
                            }}
                          />
                        </IconButton>
                      </CardContent>
                    </Card>
                  </Grid>
                )
              )}
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              //blue border outline
              border: "5px solid #2196f3",

              //round corners
              borderRadius: "10px",
              padding: "10px",
              //margin top
              marginTop: "20px",
            }}
          >
            <Typography variant="h6">All Assets</Typography>
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                style={{
                  background: "white",
                  borderRadius: "10px",
                  padding: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "20px",
                  scale: "0.9",
                  //purple outline
                  border: "5px solid #9c27b0",
                }}
              >
                <TextField
                  label="Asset URL"
                  value={newAssetUrl}
                  onChange={(e) => setNewAssetUrl(e.target.value)}
                  style={{
                    marginRight: "10px",
                  }}
                />
                <TextField
                  label="Asset Name"
                  value={newAssetName}
                  onChange={(e) => setNewAssetName(e.target.value)}
                  style={{
                    marginRight: "10px",
                  }}
                />
                <IconButton onClick={handleAddAsset}>
                  <AddIcon
                    style={{
                      color: "black",
                    }}
                  />
                </IconButton>
              </Grid>

              {assets.map((asset, index) => (
                <Grid item key={asset.id}>
                  <Card
                    style={{
                      // height: "150px",
                      width: "100px",
                      //center it all
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      //purple outline
                      border: "5px solid #9c27b0",
                      //round corners
                      borderRadius: "10px",
                    }}
                  >
                    <CardContent
                      style={{
                        //center it all
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <URLImage
                        url={asset.url}
                        style={{
                          height: "100px",
                          width: "100px",
                        }}
                      />
                      <Typography
                        style={{
                          color: "#9c27b0",
                          //bold
                          fontWeight: "bold",
                        }}
                      >
                        {asset.name}
                      </Typography>
                      <IconButton
                        onClick={() => handleDeleteGlobalAsset(asset.id)}
                      >
                        <DeleteIcon
                          style={{
                            color: "#9c27b0",
                          }}
                        />
                      </IconButton>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Dialog
        open={addAssetDialogOpen}
        onClose={() => setAddAssetDialogOpen(false)}
      >
        <DialogTitle>Add Asset to Canvas</DialogTitle>
        <DialogContent>
          <Typography>Select an asset to add to the canvas:</Typography>
          <Grid container spacing={2}>
            {assets.map((asset) => (
              <Grid item key={asset.id}>
                <Card
                  style={{
                    //purple outline
                    border: "5px solid #9c27b0",
                    //round corners
                    borderRadius: "10px",
                  }}
                >
                  <CardContent
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    <URLImage url={asset.url} />
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#9c27b0",
                      }}
                    >
                      {asset.name}
                    </Typography>
                    <Button
                      color="primary"
                      onClick={() => handleAddAssetToCanvas(asset)}
                    >
                      Add to Canvas
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setAddAssetDialogOpen(false)}
            style={{
              color: "white",
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CanvasEditor;
