import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import Editor from "@monaco-editor/react";
import { editor } from "monaco-editor";
import {
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  AppBar,
  IconButton,
  Toolbar,
  Chip,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  Switch,
} from "@material-ui/core";
import axios from "axios";
//import play arrow icon from mui old version
import PlayArrowIcon from "@material-ui/icons/PlayArrow";

import GroupAddIcon from "@material-ui/icons/GroupAdd";
import PersonIcon from "@material-ui/icons/Person";
import CircularProgress from "@material-ui/core/CircularProgress";
//import snackbar
import Snackbar from "@material-ui/core/Snackbar";
import "./App.css";
//import common drawer js from same folder
import CommonDrawer from "./CommonDrawer.js";
import CanvasEditor from "./CanvasEditor";
//navigate
import { useNavigate } from "react-router-dom";
//import firebase
import firebase from "firebase/compat/app";
import { makeStyles } from "@material-ui/core/styles";

import { Toaster, toast } from "react-hot-toast";
//import firebase rtdb
import "firebase/compat/database";
//floating action button
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { FloatingActionButton } from "material-ui";

const firebaseConfig = {
  apiKey: "AIzaSyCDM7KoL4QPJX1gln60thnslUukA4_Nd-U",
  authDomain: "ai-game-maker.firebaseapp.com",
  projectId: "ai-game-maker",
  storageBucket: "ai-game-maker.appspot.com",
  messagingSenderId: "100852636069",
  appId: "1:100852636069:web:d36e2204a6e62cd15fb04c",
  measurementId: "G-0P6M2SNKKW",
  //rtdb
  databaseURL: "https://ai-game-maker-default-rtdb.firebaseio.com/",
};
var db;
function Projects() {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
    const db1 = firebase.database();
    db = db1;
  } else {
    firebase.app(); // if already initialized, use that one
    const db1 = firebase.database();
    db = db1;
  }
  const navigate = useNavigate();
  if (localStorage.getItem("uuid") === null) {
    navigate("/");
  }

  const auth = firebase.auth();

  return (
    <>
      <Toaster />
      <CommonDrawer />

      <div
        //className="App"
        style={{
          //backgroundColor: "#FFFFFF",
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          // justifyContent: "space-evenly",
        }}
      >
        <AppBar
          position="static"
          style={{
            backgroundColor: "#000000",
            height: "7vh",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            //self aligns to the top
            alignSelf: "flex-start",
          }}
        >
          <Typography
            variant="h6"
            style={{
              flexGrow: 1,
              //bold
              fontWeight: 600,
              paddingLeft: "20px",
            }}
          >
            Helicity.ai✨ - My Projects🔰
          </Typography>

          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => {
                //set firebase auth state to null
                auth.signOut();

                //rmeove uuid from local storage
                localStorage.removeItem("uuid");
                //redirect to login page with react router navigate

                navigate("/");
              }}
            >
              <PersonIcon
                style={{
                  //make it smaller
                  width: "30px",
                  height: "30px",
                }}
              />
            </IconButton>
          </Toolbar>
        </AppBar>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "80%",
            height: "80vh",
            alignSelf: "center",
            backgroundColor: "#1E1E1E",
            color: "#FFF",
            borderRadius: "10px",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
            padding: "20px",
            marginTop: "30px",
          }}
        >
          <ProjectView />
        </div>
      </div>
    </>
  );
}

const ProjectCard = ({
  title,
  timestamp,
  tags,
  details,
  handleDialogOpenEdit,
  is3d,
}) => {
  const [hover, setHover] = useState(false);
  const handleMouseEnter = () => setHover(true);
  const handleMouseLeave = () => setHover(false);

  const onDelete = async (title) => {
    // Ask user in a message box if they are sure they want to delete
    const confirm = window.confirm(
      "Are you sure you want to delete this project?  This action cannot be undone!"
    );
    if (confirm) {
      //if yes, then delete
      //if no, then do nothing

      await axios.post(
        "https://us-central1-ai-game-maker.cloudfunctions.net/api/DeleteProject",
        {
          uuid: localStorage.getItem("uuid"),
          title: title,
        }
      );
      window.location.reload();
    }
  };

  return (
    <Card
      style={{
        width: "17vw",
        borderRadius: "10px",
        transition: "all 0.3s ease",
        transform: hover ? "scale(1.03)" : "none",
        boxShadow: hover ? "0 8px 16px 0 rgba(0,0,0,0.2)" : "none",
        border: hover ? "5px solid #FC0FC0" : "none",
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      //check if card is clicked but not some other element inside it
      onClick={(e) => {
        //go to /ide?project={title}
        //check if this project is 3d or 2d
        if (is3d) {
          window.location.href = "/gide?project=" + title;
        } else {
          window.location.href = "/ide?project=" + title;
        }
      }}
    >
      <CardContent
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <img
          src="https://miro.medium.com/v2/resize:fit:1024/1*dCjudI8o8dcAhevS3CtEvA.png"
          style={{
            borderRadius: "25%",
            width: "60px",
            height: "60px",
          }}
        />
        {/* <Typography
          variant="h5"
          component="div"
          style={{
            //bold
            fontWeight: 600,
          }}
        >
          {title}
        </Typography> */}
        <TextField
          id="outlined-basic"
          //h5
          // label="Title"
          variant="outlined"
          style={{
            //bold
            fontWeight: 600,
            marginTop: "10px",
          }}
          value={title}
          onClick={(e) => {
            e.stopPropagation(); // prevent card click
            // handle content click
          }}
        />

        <Typography
          variant="body2"
          color="text.secondary"
          style={{
            //bold
            fontWeight: 600,
          }}
        >
          Created: {timestamp}
        </Typography>
        {tags.map((tag, index) => (
          <Chip
            key={index}
            label={tag}
            style={{
              backgroundColor: "#FC0FC0",
              color: "#000000",
              //bold
              width: "30%",
              margin: "5px",
            }}
          />
        ))}
        <Typography
          variant="body2"
          color="text.secondary"
          style={{
            //bold
            fontWeight: 600,
          }}
        >
          {details}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={(e) => {
            e.stopPropagation(); // prevent card click
            // handle content click
            //you have to tell project name to the parent
            handleDialogOpenEdit(title);
          }}
        >
          Edit
        </Button>
        <Button
          variant="contained"
          color="secondary"
          style={{
            transition: "all 0.3s ease",
            transform: "scale(1)",
            "&:active": {
              transform: "scale(0.95)",
            },
          }}
          onClick={(e) => {
            e.stopPropagation(); // prevent card click
            // handle content click
            onDelete(title);
          }}
        >
          Delete
        </Button>
      </CardContent>
    </Card>
  );
};

const SampleCard = ({
  title,
  sampleName,
  timestamp,
  tags,
  details,
  iconURL,
  BG,
  handleCreate,
}) => {
  const [hover, setHover] = useState(false);
  const handleMouseEnter = () => setHover(true);
  const handleMouseLeave = () => setHover(false);

  return (
    <Card
      style={{
        width: "17vw",
        borderRadius: "10px",
        transition: "all 0.3s ease",
        transform: hover ? "scale(1.03)" : "none",
        boxShadow: hover ? "0 8px 16px 0 rgba(0,0,0,0.2)" : "none",
        border: hover ? "5px solid #FC0FC0" : "none",

        background: BG,
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => {
        handleCreate(sampleName);
      }}
    >
      <CardContent
        style={{
          display: "flex",
          flexDirection: "column",
          //bg image
        }}
      >
        <img
          src={iconURL}
          style={{
            borderRadius: "25%",
            width: "60px",
            height: "60px",
          }}
        />

        <TextField
          id="outlined-basic"
          //h5
          // label="Title"
          variant="outlined"
          style={{
            //bold
            fontWeight: 600,
            marginTop: "10px",
          }}
          value={title}
        />

        <Chip
          label={tags[0]}
          style={{
            backgroundColor: "#FC0FC0",
            color: "#000000",
            //bold
            width: "30%",
            margin: "5px",
          }}
        />

        <Typography
          variant="body2"
          color="text.secondary"
          style={{
            //bold
            fontWeight: 600,
          }}
        >
          {details}
        </Typography>
      </CardContent>
    </Card>
  );
};

const ProjectView = () => {
  const [projects, setProjects] = useState(["loading"]);
  const [openNew, setOpenNew] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [newProject, setNewProject] = useState({
    title: "",
    timestamp: "",
    tags: ["CLOUD"],
    details: "",
    code: "",
    canvasState: "",
    is3d: false,
  });
  const [editedProject, setEditedProject] = useState({
    title: "",
    details: "",
  });
  const [hover, setHover] = useState(false);
  const handleMouseEnter = () => setHover(true);
  const handleMouseLeave = () => setHover(false);

  useEffect(() => {
    const uuid = localStorage.getItem("uuid");
    axios
      .post(
        "https://us-central1-ai-game-maker.cloudfunctions.net/api/GetProjects",
        {
          uuid: uuid,
        }
      )
      .then((response) => {
        setProjects(response.data.projects);
      })
      .catch((error) => {
        // alert(error);
      });
  }, []);

  const handleDialogOpenNew = () => {
    setOpenNew(true);
  };

  const handleDialogCloseNew = () => {
    setOpenNew(false);
  };

  const handleDialogOpenEdit = (title) => {
    //set editedProject to the project with title
    setEditedProject(
      // CURRENT editedProject then ...project with title
      {
        ...editedProject,
        oldTitle: title,
      }
    );
    setOpenEdit(true);
  };

  const handleDialogCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleCreate = async (template) => {
    //if title is not empty
    if (!template) {
      if (newProject.title === "") {
        toast.error("Please enter a title!");
        return;
      }
    }

    setOpenNew(false);
    //SET NEW PROJECTS TIMESTMP TO CURRENT TIME IN EPOCH seconds

    // setProjects([...projects, newProject]);
    if (!template) {
      await axios
        .post(
          "https://us-central1-ai-game-maker.cloudfunctions.net/api/CreateProject",
          {
            uuid: localStorage.getItem("uuid"),
            project: newProject,
            useTemplate: false,
          }
        )
        .then((response) => {
          if (response.data.success) {
            toast.success("Project Created Successfully!");
            window.location.reload();
          } else {
            if (response.data.reason === "limit")
              toast.error("Project Creation Failed! Please Upgrade Plan!");
          }
        });
    } else {
      await axios
        .post(
          "https://us-central1-ai-game-maker.cloudfunctions.net/api/CreateProject",
          {
            uuid: localStorage.getItem("uuid"),

            useTemplate: true,
            template: template,
          }
        )
        .then((response) => {
          if (response.data.success) {
            toast.success("Project Created Successfully!");
            window.location.reload();
          } else {
            if (response.data.reason === "limit")
              toast.error("Project Creation Failed! Please Upgrade Plan!");
          }
        });
    }
  };

  const handleInputChange = (event) => {
    setNewProject({
      ...newProject,
      [event.target.id]: event.target.value,
    });
  };

  const switch3d = () => {
    setNewProject({
      ...newProject,
      is3d: !newProject.is3d,
    });
  };

  const handleInputChangeEdit = (event) => {
    setEditedProject({
      ...editedProject,
      [event.target.id]: event.target.value,
    });
  };

  const manualCreate = () => {
    handleCreate(false);
  };

  const manualEdit = async () => {
    //if title is not empty
    if (editedProject.title === "") {
      toast.error("Please enter a title!");
      return;
    }

    await axios.post(
      "https://us-central1-ai-game-maker.cloudfunctions.net/api/EditProjectTitleAndDetails",
      {
        uuid: localStorage.getItem("uuid"),
        title: editedProject.title,
        oldTitle: editedProject.oldTitle,
        details: editedProject.details,
      }
    );
    window.location.reload();
  };

  return (
    <div
      style={{
        overflowY: "scroll",
        overflowX: "hidden",
        padding: "20px",
        width: "100%",
      }}
    >
      <Typography variant="h5" component="div" style={{ fontWeight: 600 }}>
        My Cloud Projects
      </Typography>
      {/* <Button variant="contained" color="primary" onClick={handleDialogOpen}>
        Create New Project
      </Button> */}
      <Fab
        color="primary"
        aria-label="add"
        style={{
          position: "absolute",
          top: "11vh",
          left: "3vw",
          backgroundColor: "#FC0FC0",
          color: "#000000",
        }}
        onClick={handleDialogOpenNew}
      >
        <AddIcon />
      </Fab>
      {projects.length === 1 && projects[0] === "loading" && (
        //this meansall projects are still loading
        <div
          style={{
            //center it all
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {" "}
          <img
            src="https://miro.medium.com/v2/resize:fit:1024/1*dCjudI8o8dcAhevS3CtEvA.png"
            style={{
              width: "100px",
              height: "100px",
              marginTop: "20px",
              borderRadius: "25%",
            }}
          />
          <Typography
            variant="h6"
            color="text.secondary"
            style={{
              fontWeight: 600,
              marginTop: "20px",
            }}
          >
            Loading your projects from the cloud...
            <CircularProgress
              style={{
                marginTop: "20px",
                marginLeft: "20px",
                //size smaller
                width: "20px",
                height: "20px",
                //colour pink
                color: "#FC0FC0",
              }}
            />
          </Typography>
        </div>
      )}
      {projects.length === 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "20px",
            selfAlign: "center",
          }}
        >
          <img
            src="https://miro.medium.com/v2/resize:fit:1024/1*dCjudI8o8dcAhevS3CtEvA.png"
            style={{
              width: "100px",
              height: "100px",
              marginTop: "20px",
              borderRadius: "25%",
            }}
          />

          <Typography
            variant="h6"
            color="text.secondary"
            style={{
              fontWeight: 600,
              marginTop: "20px",
            }}
          >
            Let's create a new project to get started!
          </Typography>
        </div>
      )}

      {
        // the projects part
      }
      <Grid container spacing={4} style={{ marginTop: "20px" }}>
        {projects.length > 0 &&
          projects[0] !== "loading" &&
          projects.map((project, index) => (
            <Grid item key={index}>
              <ProjectCard
                title={project.title}
                timestamp={project.timestamp}
                tags={project.tags}
                details={project.details}
                handleDialogOpenEdit={handleDialogOpenEdit}
                is3d={project.is3d}
              />
            </Grid>
          ))}
      </Grid>

      {
        // the sample projects part
      }
      <Typography
        variant="h5"
        component="div"
        style={{ fontWeight: 600, marginTop: "20px" }}
      >
        Some Beautiful Projects from the Community!
      </Typography>
      <Grid container spacing={4} style={{ marginTop: "20px" }}>
        <Grid item>
          <SampleCard
            title={"Flappy Bird"}
            sampleName={"flappybird"}
            timestamp={"2021-08-01"}
            tags={["CLOUD"]}
            details={
              " A flappy bird game that you can remix and experiment with! Click to copy this project to your cloud."
            }
            iconURL={
              "https://upload.wikimedia.org/wikipedia/en/0/0a/Flappy_Bird_icon.png"
            }
            BG={
              //light yellow and very light red gradient
              "linear-gradient(90deg, rgba(255,250,205,1) 0%, rgba(255,204,204,1) 50%, rgba(255,204,204,1) 100%)"
            }
            handleCreate={handleCreate}
          />
        </Grid>
        <Grid item>
          <SampleCard
            title={"Slither.io Movement Prototype"}
            sampleName={"slither"}
            timestamp={"2021-08-01"}
            tags={["CLOUD"]}
            details={
              " A snake movement prototype that you can remix and experiment with! Click to copy this project to your cloud."
            }
            iconURL={
              "https://upload.wikimedia.org/wikipedia/en/5/50/Slither.png"
            }
            BG={
              "linear-gradient(90deg, rgba(204,255,204,1) 0%, rgba(204,255,255,1) 50%, rgba(204,204,255,1) 100%)"
            }
            handleCreate={handleCreate}
          />
        </Grid>
      </Grid>
      <Dialog
        open={openNew}
        onClose={handleDialogCloseNew}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Create New Project</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="title"
            label="Project Title"
            type="text"
            fullWidth
            value={newProject.title}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            id="details"
            label="Project Details"
            type="text"
            fullWidth
            value={newProject.details}
            onChange={handleInputChange}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <Switch
              id="is3d"
              checked={newProject.is3d}
              onChange={switch3d}
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
            <Typography variant="body1" color="text.secondary">
              {newProject.is3d ? "3D" : "2D"}
            </Typography>
          </div>

          {/* You can add more fields as per your requirement */}
          <Button onClick={manualCreate} color="primary">
            Add
          </Button>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openEdit}
        onClose={handleDialogCloseEdit}
        aria-labelledby="form-dialog-title-1"
      >
        <DialogTitle id="form-dialog-title-1">Edit this Project</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="title"
            label="Project Title"
            type="text"
            fullWidth
            value={editedProject.title}
            onChange={handleInputChangeEdit}
          />

          <TextField
            margin="dense"
            id="details"
            label="Project Details"
            type="text"
            fullWidth
            value={editedProject.details}
            onChange={handleInputChangeEdit}
          />
          {/* You can add more fields as per your requirement */}
          <Button onClick={manualEdit} color="primary">
            Edit
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Projects;
