import React, { useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { Card, CardContent, Typography } from "@material-ui/core";

import { useNavigate } from "react-router-dom";

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyCDM7KoL4QPJX1gln60thnslUukA4_Nd-U",
  authDomain: "ai-game-maker.firebaseapp.com",
  projectId: "ai-game-maker",
  storageBucket: "ai-game-maker.appspot.com",
  messagingSenderId: "100852636069",
  appId: "1:100852636069:web:d36e2204a6e62cd15fb04c",
  measurementId: "G-0P6M2SNKKW",
};

const Login = () => {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }
  // Configure FirebaseUI
  const uiConfig = {
    signInFlow: "popup",
    signInSuccessUrl: "/projects",
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      // Add other sign-in providers if needed
    ],
  };
  const auth = firebase.auth();
  const navigate = useNavigate();
  useEffect(() => {
    // Check if user is already signed in
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // User is signed in, handle accordingly
        //save uuid in local storage
        localStorage.setItem("uuid", user.uid);

        navigate("/projects");
      } else {
        // User is signed out
        //remove uuid from localstorage if any
        localStorage.removeItem("uuid");
      }
    });

    return () => {
      unsubscribe();
    };
  }, [auth]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <style jsx global>{`
        body {
          background: linear-gradient(
            90deg,
            rgba(2, 0, 36, 1) 0%,
            rgba(9, 9, 121, 1) 35%,
            rgba(0, 212, 255, 1) 100%
          );
        }
      `}</style>
      <Card
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
          marginBottom: "20px",
          borderRadius: "20px",
          boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
          width: "40%",
        }}
      >
        <CardContent
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h2"
            style={{
              textAlign: "center",
              marginTop: "20px",
              marginBottom: "20px",
              fontWeight: "bold",
            }}
          >
            Login
          </Typography>
          <Typography
            variant="h4"
            style={{
              textAlign: "center",
              marginTop: "20px",
              marginBottom: "20px",
              fontWeight: "bold",
            }}
          >
            With your Google Account📍
          </Typography>
          <div>
            <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default Login;
