import IDE from "./IDE";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./Login";
import SceneEditor from "./SceneEditor";
import UIDE from "./UIDE";
import GIDE from "./GIDE";
import Projects from "./Projects";
import Analytics from "./Analytics";

function App() {
  //Depending on the route we are on, we will render a different component
  //We will use the react-router-dom library to help us with this
  return (
    <>
      <style jsx global>{`
        body {
          background: linear-gradient(
            90deg,
            rgba(2, 0, 36, 1) 0%,
            rgba(9, 9, 121, 1) 35%,
            rgba(0, 212, 255, 1) 100%
          );
        }
      `}</style>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/ide" element={<IDE />} />
          <Route path="/scene-editor" element={<SceneEditor />} />
          {/* <Route path="/uide" element={<UIDE />} /> */}
          <Route path="/projects" element={<Projects />} />
          <Route path="/analytics" element={<Analytics />} />
          <Route path="/gide" element={<GIDE />} />
        </Routes>
      </Router>
    </>
  );
}
export default App;
