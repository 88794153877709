import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import Editor from "@monaco-editor/react";
import { editor } from "monaco-editor";
import {
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  AppBar,
  IconButton,
  Toolbar,
  Slider,
  Fab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  ThemeProvider,
  createMuiTheme,
  Switch,
} from "@material-ui/core";
import axios from "axios";
//import play arrow icon from mui old version
import PlayArrowIcon from "@material-ui/icons/PlayArrow";

import GroupAddIcon from "@material-ui/icons/GroupAdd";
import PersonIcon from "@material-ui/icons/Person";
//attach money
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import CircularProgress from "@material-ui/core/CircularProgress";
//import snackbar
import Snackbar from "@material-ui/core/Snackbar";
import "./App.css";
//import common drawer js from same folder
import CommonDrawer from "./CommonDrawer.js";
import AddIcon from "@mui/icons-material/AutoAwesome";
//auto fix high icon from mui
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";

//auto awsome

//navigate
import { useNavigate } from "react-router-dom";
//import firebase
import firebase from "firebase/compat/app";
// import { user } from "firebase-functions/v1/auth";
//fix some polyfills issue
import "firebase/compat/auth";
import "firebase/compat/functions";
import UIDE from "./UIDE";
import PubSub from "./pubsub.js";
///react hot toast
import { Toaster, toast } from "react-hot-toast";

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyCDM7KoL4QPJX1gln60thnslUukA4_Nd-U",
  authDomain: "ai-game-maker.firebaseapp.com",
  projectId: "ai-game-maker",
  storageBucket: "ai-game-maker.appspot.com",
  messagingSenderId: "100852636069",
  appId: "1:100852636069:web:d36e2204a6e62cd15fb04c",
  measurementId: "G-0P6M2SNKKW",
};
const theme = createMuiTheme({
  overrides: {
    MuiDialog: {
      paper: {
        backgroundColor: "#000",
        color: "#fff",
      },
    },
  },
});
const monaco = editor;
function CodeEditor({ code, setCode }) {
  const editorRef = useRef(null);

  useEffect(() => {
    if (editorRef.current) {
      const currentModel = editorRef.current.getModel();
      if (currentModel) {
        currentModel.setValue(code);
      } else {
        const newModel = monaco.editor.createModel(code, "javascript");
        editorRef.current.setModel(newModel);
      }
    }
  }, [code]);

  return (
    <Editor
      height="90vh"
      defaultLanguage="javascript"
      theme="vs-dark"
      editorDidMount={(editor) => {
        editorRef.current = editor;
      }}
      value={code}
      onChange={(value) => {
        setCode(value);
      }}
      preserveValue
      options={{
        minimap: {
          enabled: false,
        },
      }}
    />
  );
}

function IDE() {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }
  const navigate = useNavigate();
  if (localStorage.getItem("uuid") === null) {
    navigate("/");
  }

  const auth = firebase.auth();

  //URL params
  const params = new URLSearchParams(window.location.search);
  const projectName = params.get("project");

  const [code, setCode] = useState("");

  const [userPrompt, setUserPrompt] = useState("Make flappy bird");
  const [generating, setGenerating] = useState(false);
  const [generating2, setGenerating2] = useState(false);
  const [explanation, setExplanation] = useState("");

  const [playClicked, setPlayClicked] = useState(false);

  const [sliderValue, setSliderValue] = useState(30);
  const [credits, setCredits] = useState("Loading");
  const [portalClicked, setPortalClicked] = useState(false);
  const [plan, setPlan] = useState(0);
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [canvasCode, setCanvasCode] = useState("");
  const [globalGenerating, setGlobalGenerating] = useState(0);
  const [fab1Scale, setFab1Scale] = useState(1);
  const [fab2Scale, setFab2Scale] = useState(1);

  //use effect play clicked
  useEffect(() => {
    if (playClicked) {
      //toast loading game
      toast("Loading game...");
    }
    var canvas_state = JSON.parse(localStorage.getItem("canvasState"));
    //if canvas state is empty, return

    if (typeof canvas_state != "object") {
      canvas_state = [];
    }
    if (!canvas_state) {
      canvas_state = [];
    }
  }, [playClicked]);

  useEffect(() => {
    //get the current code which is being written , and replace canvasCode the place where //CANVAS_START to //CANVAS_END is

    var currentCode = code;
    //replace the part of this code from "//CANVAS_START" to "//CANVAS_END" with canvasCode
    var newCode = "";
    //split the current code intot the canvas region
    var start = currentCode.indexOf("//CANVAS_START");
    var end = currentCode.indexOf("//CANVAS_END");
    //get the part before the canvas region
    newCode += currentCode.substring(0, start);
    //add the canvas code

    newCode += "//CANVAS_START\n" + canvasCode + "\n//CANVAS_END\n";
    //add the part after the canvas region
    newCode += currentCode.substring(end + 13);

    //set the new code
    setCode(newCode);
  }, [canvasCode]);

  useEffect(async () => {
    // axios GetProject
    //make a post request to the backend
    const URL =
      "https://us-central1-ai-game-maker.cloudfunctions.net/api/GetProject";
    var response = await axios.post(URL, {
      uuid: localStorage.getItem("uuid"),
      title: projectName,
    });

    setCode(response.data.project.code);
    localStorage.setItem(
      "canvasState",
      JSON.stringify(response.data.project.canvasState)
    );

    PubSub.publish(
      "canvas.first-time-cloud-load",
      response.data.project.canvasState
    );
    //CHANGE THIS

    refreshCredits();

    const handleMyEvent = (data) => {
      // alert("Received message:");
      // alert(JSON.stringify(data));
      //SET current code
      var currentCanvas = "";
      //split the msg and take the part starting from [ and ending at ] both inclusive
      var start = data.indexOf("[");
      var end = data.indexOf("]");
      currentCanvas = data.substring(start + 1, end);
      //set the current canvas
      currentCanvas = "[\n" + currentCanvas + "\n]";

      PubSub.publish("canvas.change", currentCanvas);
    };

    const handleMyEvent2 = (data) => {
      //write the inital code the first time
      var newCode = "";
      newCode += `
//Importing all the modules
import { GameObject } from "./Gameobject.js";
import { Input } from "./Input.js";
import { Physics } from "./Physics.js";
import { Renderer } from "./Renderer.js";
import { Game } from "./Engine.js";\n\n`;
      newCode += "//CANVAS_START\n";

      newCode += data;
      newCode += "\n//CANVAS_END\n\n";
      newCode += "Game.start();";

      //set the new code
      setCode(newCode);

      //now launch dialog 2   PLEASE FUCKING CHANGE THIS WHEN NEEDED
      setOpen2(false);
    };

    const handleMyEvent3 = (data) => {
      //set the current file to data
      setCode(data);
    };

    PubSub.subscribe("canvas.first-time-change", handleMyEvent2);

    PubSub.subscribe("code.change", handleMyEvent);
    PubSub.subscribe("code.finish", handleMyEvent3);
  }, []);

  //use effect for global generating
  useEffect(() => {
    var scaleOscillator = 1;
    //set that variable to keep oscillating with a set interval

    var interval = setInterval(() => {
      //map the value from -1 to 1 to 0.7 to 1.3
      scaleOscillator = 1.2 + 0.2 * Math.sin((6 * Date.now()) / 1000);

      switch (globalGenerating) {
        case 0:
          setFab1Scale(scaleOscillator);

          setFab2Scale(1);
          break;
        case 1:
          setFab1Scale(1);
          setFab2Scale(1);
          break;
        case 2:
          setFab1Scale(1);
          setFab2Scale(scaleOscillator);
      }
    }, 10);

    //clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, [globalGenerating]);

  //use effect code
  useEffect(() => {
    //if code is empty, return
    if (code == "") {
      return;
    }
    //alert("autosaving project");
    if (code.includes("import")) {
      var canvas_state = JSON.parse(localStorage.getItem("canvasState"));
      //if canvas state is empty, return

      if (typeof canvas_state != "object") {
        canvas_state = [];
      }
      if (!canvas_state) {
        canvas_state = [];
      }
      //add a object with id background to this canvas state

      // alert(
      //   JSON.stringify({
      //     uuid: localStorage.getItem("uuid"),
      //     code: code,
      //     projectName: projectName,
      //     canvasState: canvas_state,
      //   })
      // );

      axios
        .post(
          "https://us-central1-ai-game-maker.cloudfunctions.net/api/AutoSaveProject",
          {
            uuid: localStorage.getItem("uuid"),
            code: code,
            projectName: projectName,
            canvasState: canvas_state,
          }
        )
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          alert("Error autosaving project");
          alert(JSON.stringify(err));
        });
    }
  }, [code]);

  // function submitCode() {
  //   console.log("submitting code");
  //   //make a post request to the backend
  //   const URL =
  //     "https://us-central1-ai-game-maker.cloudfunctions.net/api/submit";
  //   axios
  //     .post(URL, {
  //       code: code,
  //       uuid: localStorage.getItem("uuid"),
  //     })
  //     .then((res) => {
  //       console.log(res);
  //     });
  // }

  function generateAI() {
    console.log("submitting your prompt");
    if (userPrompt === "") {
      alert("Please enter a prompt");
      return;
    }
    //Its langchain now
    const URL =
      "https://us-central1-ai-game-maker.cloudfunctions.net/api/AIGenRequest";
    axios
      .post(URL, {
        userPrompt: localStorage.getItem("savedUserPrompt"),

        uuid: localStorage.getItem("uuid"),
        temperature: sliderValue / 100,
        model: "gpt-3.5-turbo",
      })
      .then((res) => {
        //console.log(res.data.ans);
        setGenerating(false);
        //set the editor text to res.dat
        setCode(res.data.main);
        setExplanation(res.data.explanation);
        //edit the canvas state

        setOpen1(false);

        //refresh credits
        refreshCredits();
      })
      .catch((err) => {
        //if errorType is insufficient credits toast

        toast.error("Insufficient Credits, please upgrade your plan!");
      });
  }

  function requestCreateGame() {
    //if credits <2 toast insufficient credits
    if (typeof credits == "number") {
      if (credits < 2) {
        setPlayClicked(false);

        toast.error("Insufficient Credits, please upgrade your plan!");
        return;
      }
    }
    console.log("submitting code");
    //make a post request to the backend
    const URL =
      "https://us-central1-ai-game-maker.cloudfunctions.net/api/createGame";
    axios
      .post(URL, {
        code: code,
        uuid: localStorage.getItem("uuid"),
        //game title
        title: projectName,
      })
      .then((res) => {
        console.log(res);
        //go to res.data.publicURL IN NEW PAGE
        setPlayClicked(false);
        window.open(res.data.url, "_blank");

        //refresh credits
        refreshCredits();
      });
  }

  function handleSliderChange(event, newValue) {
    setSliderValue(newValue);
  }

  function refreshCredits() {
    console.log("refreshing credits");
    //make a post request to the backend
    const URL =
      "https://us-central1-ai-game-maker.cloudfunctions.net/api/RefreshCredits";
    axios
      .post(URL, {
        uuid: localStorage.getItem("uuid"),
      })
      .then((res) => {
        // alert(JSON.stringify(res.data));
        var c = res.data.credits;
        if (c) {
          if (c >= -30) {
            setCredits(c);
            setPlan(res.data.plan);
            //alert("plan is" + res.data.plan);
          }
        }
      });
  }

  return (
    <>
      <>
        <Toaster
          //toast bold
          toastOptions={{
            style: {
              fontWeight: "bold",
            },
          }}
        />
        <div>
          <FloatingActionButton
            stye={{
              //sticky
              position: "fixed",
              top: "10vh",
              left: "90vw",
            }}
          />
          <CommonDrawer />
          {/* <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={playClicked}
            message="Loading game..."
            //green snackbar for success
            //success
            severity="success"
          /> */}
          <div
            //className="App"
            style={{
              //backgroundColor: "#FFFFFF",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <AppBar
              position="static"
              style={{
                backgroundColor: "#000000",
                height: "7vh",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                //self aligns to the top
                alignSelf: "flex-start",
              }}
            >
              <Typography
                variant="h6"
                style={{
                  flexGrow: 1,
                  //bold
                  fontWeight: 600,
                  paddingLeft: "20px",
                }}
              >
                Helicity.ai Beta V1.2🚀{"  "}
                {
                  //based on globalGenerating, show a circular progress bar

                  globalGenerating == 0 ? (
                    <></>
                  ) : globalGenerating == 1 ? (
                    <>
                      {"     (1/3) "}Creating your Game Scene...
                      <CircularProgress
                        style={{
                          color: "#FF00FF",
                          //make it smaller
                          width: "30px",
                          height: "30px",
                        }}
                      />
                    </>
                  ) : globalGenerating == 2 ? (
                    "     (2/3) Tell us about your game..."
                  ) : globalGenerating == 3 ? (
                    <>
                      {"     (3/3) "}Creating your Game ... Please wait for 2
                      minutes...
                      <CircularProgress
                        style={{
                          color: "#FF00FF",
                          //make it smaller
                          width: "30px",
                          height: "30px",
                        }}
                      />
                    </>
                  ) : (
                    //last case
                    ""
                  )
                }
              </Typography>

              <Toolbar>
                <Typography
                  variant="h6"
                  style={{
                    flexGrow: 1,
                    //bold
                    fontWeight: 600,
                    paddingRight: "4vw",
                  }}
                >
                  {credits}✨
                </Typography>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={() => {
                    if (!playClicked) {
                      requestCreateGame();
                      //set is playing to true
                      if (credits >= 2) {
                        setPlayClicked(true);
                        //toast insufficent credits
                      } else {
                        toast.error(
                          "Insufficient Credits, please upgrade your plan!"
                        );
                        //alrt asking open old url?
                        if (
                          window.confirm(
                            "You have insufficient credits, would you like to open your old game?"
                          )
                        ) {
                          //open the old url
                          window.open(
                            "https://storage.googleapis.com/ai-game-maker.appspot.com/public/" +
                              localStorage.getItem("uuid") +
                              ".html",
                            "_blank"
                          );
                        }
                      }
                    }
                  }}
                >
                  {playClicked ? (
                    //loading icon pink
                    <CircularProgress
                      style={{
                        color: "#FF00FF",
                        //make it smaller
                        width: "30px",
                        height: "30px",
                      }}
                    />
                  ) : (
                    <PlayArrowIcon
                      style={{
                        //make it smaller
                        width: "30px",
                        height: "30px",
                      }}
                    />
                  )}
                </IconButton>

                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={() => {
                    //log out
                    localStorage.removeItem("uuid");
                    //go to login page
                    window.location.href = "/";
                  }}
                >
                  <PersonIcon
                    style={{
                      //make it smaller
                      width: "30px",
                      height: "30px",
                    }}
                  />
                </IconButton>

                <Button
                  variant="contained"
                  color="secondary"
                  style={{
                    //bg color is a gradient of dark green and dark
                    background:
                      "linear-gradient(90deg, rgba(0,255,0,1) 0%, rgba(0,255,0,1) 50%, rgba(0,255,0,1) 100%)",
                    height: "40px",
                    //bold font
                    fontWeight: 600,
                    //black text
                    color: "#000000",
                  }}
                  onClick={() => {
                    //make this button invisible for 3 seconds
                    setPortalClicked(true);
                    //call firebase v1 firebase function createPortalSession
                    // Get a reference to the Cloud Function
                    const URL =
                      "https://us-central1-ai-game-maker.cloudfunctions.net/api/CreatePortalSession";
                    axios
                      .post(URL, {
                        uuid: localStorage.getItem("uuid"),
                      })
                      .then((res) => {
                        //open the url in a new tab
                        setPortalClicked(false);
                        window.open(res.data.url, "_blank");
                      })
                      .catch((err) => {
                        alert("Error creating portal session");
                      });
                  }}
                >
                  <IconButton edge="start" color="inherit" aria-label="menu">
                    {!portalClicked ? (
                      <AttachMoneyIcon
                        style={{
                          //make it smaller
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    ) : (
                      //circular progress bar
                      <CircularProgress
                        style={{
                          color: "#FF00FF",
                          //make it smaller
                          width: "30px",
                          height: "30px",
                        }}
                      />
                    )}
                  </IconButton>
                  {plan == 0 ? "Free" : "Pro🔥"}
                </Button>
              </Toolbar>
            </AppBar>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "80%",
                height: "80vh",
                alignSelf: "center",
                backgroundColor: "#1E1E1E",
                color: "#FFF",
                borderRadius: "10px",
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
                padding: "20px",
                marginTop: "30px",
              }}
            >
              <div
                style={{
                  display: "flex",

                  flexDirection: "column",
                  width: "65%",
                }}
              >
                {" "}
                <CodeEditor code={code} setCode={setCode} />
              </div>
              <div
                style={{
                  overflowX: "hidden",
                  width: "50%",
                  overflowY: "scroll",
                }}
              >
                {" "}
                <UIDE setCanvasCode={setCanvasCode} />
              </div>
            </div>
          </div>
        </div>
      </>

      {/* <FloatingActionButton
        userPrompt={userPrompt}
        generating={generating}
        setGenerating={setGenerating}
        setGlobalGenerating={setGlobalGenerating}
        generateAI={generateAI}
        sliderValue={sliderValue}
        explanation={explanation}
        setUserPrompt={setUserPrompt}
        setSliderValue={setSliderValue}
        open1={open1}
        setOpen1={setOpen1}
        open2={open2}
        setOpen2={setOpen2}
        currentFile={code}
        fabScale={fab1Scale}
      /> */}
      <FloatingActionButton2
        userPrompt={userPrompt}
        generating={generating}
        setGenerating={setGenerating}
        setGlobalGenerating={setGlobalGenerating}
        generateAI={generateAI}
        sliderValue={sliderValue}
        explanation={explanation}
        setUserPrompt={setUserPrompt}
        setSliderValue={setSliderValue}
        open1={open1}
        setOpen1={setOpen1}
        open2={open2}
        setOpen2={setOpen2}
        currentFile={code}
        fabScale={fab2Scale}
        refreshCredits={refreshCredits}
      />
    </>
  );
}

function FloatingActionButton({
  userPrompt,
  generating,
  setGenerating,
  setGlobalGenerating,
  generateAI,
  sliderValue,
  explanation,
  setUserPrompt,
  setSliderValue,
  open1,
  setOpen1,
  open2,
  setOpen2,
  currentFile,
  fabScale,
}) {
  //const [sliderValue, setSliderValue] = useState(30);
  //const [userPrompt, setUserPrompt] = useState("");
  const [gameSimilarTo, setGameSimilarTo] = React.useState("");
  const [controls, setControls] = React.useState("");
  const [images, setImages] = React.useState("");
  const [background, setBackground] = React.useState("");
  const [inputOutcome, setInputOutcome] = React.useState("");

  //ude effect on evry change on the above
  useEffect(() => {
    var promptEnineering = "";
    if (gameSimilarTo !== "") {
      promptEnineering += "Game similar to : " + gameSimilarTo + "\n";
    }
    if (controls !== "") {
      promptEnineering += "Controls are : " + controls + "\n";
    }
    if (images !== "") {
      promptEnineering += "Images to be used are : " + images + "\n";
    }
    if (background !== "") {
      promptEnineering += "Background is : " + background + "\n";
    }
    if (inputOutcome !== "") {
      promptEnineering += "The game mechanics are : " + inputOutcome + "\n";
    }
    localStorage.setItem("savedUserPrompt", promptEnineering);
  }, [gameSimilarTo, controls, images, background, inputOutcome]);

  const handleClickOpen1 = () => {
    setOpen1(true);
  };
  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };
  function handleSliderChange(event, newValue) {
    setSliderValue(newValue);
  }
  function submitCanvasRequest() {
    // alert("submitting canvas request");
    handleClose1();
    setGlobalGenerating(1);
    //make a post request to the backend
    const URL =
      "https://us-central1-ai-game-maker.cloudfunctions.net/api/CanvasAIGenRequest";
    axios
      .post(URL, {
        userPrompt:
          "Images description provided by user is : " +
          images +
          "\n and background description is" +
          background,
        uuid: localStorage.getItem("uuid"),
      })
      .then((res) => {
        setGlobalGenerating(2);

        PubSub.publish("code.change", res.data.ans);
      })
      .catch((err) => {
        toast.error("Insufficient Credits, please upgrade your plan!");
      });
  }

  return (
    <div>
      {" "}
      <div>
        <Box
          sx={{
            position: "fixed",
            top: 105,
            right: 16,
            scale: fabScale,
          }}
        >
          <Fab
            style={{
              backgroundColor: "#ffff00",
            }}
            aria-label="add"
            onClick={handleClickOpen1}
          >
            <AddIcon />
          </Fab>
        </Box>
        <ThemeProvider theme={theme}></ThemeProvider>
      </div>
    </div>
  );
}

function FloatingActionButton2({
  userPrompt,
  generating,
  setGenerating,
  setGlobalGenerating,
  generateAI,
  sliderValue,
  explanation,
  setUserPrompt,
  setSliderValue,
  open1,
  setOpen1,
  open2,
  setOpen2,
  currentFile,
  fabScale,
  refreshCredits,
}) {
  //const [sliderValue, setSliderValue] = useState(30);
  //const [userPrompt, setUserPrompt] = useState("");
  const [gameSimilarTo, setGameSimilarTo] = React.useState("");
  const [controls, setControls] = React.useState("");
  const [images, setImages] = React.useState("");
  const [background, setBackground] = React.useState("");
  const [inputOutcome, setInputOutcome] = React.useState("");
  const [useGPT4, setUseGPT4] = React.useState(true);

  ///  //const [sliderValue, setSliderValue] = useState(30);
  //const [userPrompt, setUserPrompt] = useState("");

  //ude effect on evry change on the above
  useEffect(() => {
    var promptEnineering = "";
    if (images !== "") {
      promptEnineering += "Images description provided by user is : " + images;
    }
    if (background !== "") {
      promptEnineering += " and background description is" + background;
    }

    if (gameSimilarTo !== "") {
      promptEnineering += "Game similar to: " + gameSimilarTo + "\n";
    }
    if (controls !== "") {
      promptEnineering += "Controls are: " + controls + "\n";
    }
    if (images !== "") {
      promptEnineering += "Images to be used are: " + images + "\n";
    }
    if (background !== "") {
      promptEnineering += "Background is: " + background + "\n";
    }
    if (inputOutcome !== "") {
      promptEnineering += "The game mechanics are: " + inputOutcome + "\n";
    }
    localStorage.setItem("savedUserPrompt", promptEnineering);
  }, [gameSimilarTo, controls, images, background, inputOutcome]);
  ///

  useEffect(() => {
    //if useGPT4 is true, then set the model to gpt-4
    if (useGPT4) {
      localStorage.setItem("model", "gpt-4");
    } else {
      localStorage.setItem("model", "gpt-3.5-turbo-16k");
    }
  }, [useGPT4]);

  const handleClickOpen1 = () => {
    setOpen1(true);
  };
  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };
  function handleSliderChange(event, newValue) {
    setSliderValue(newValue);
  }

  function submitFullGameRequest() {
    handleClose2();
    setGlobalGenerating(3);
    //make a post request to the backend
    const URL =
      "https://us-central1-ai-game-maker.cloudfunctions.net/api/FullAIGenRequest";

    axios
      .post(URL, {
        userPrompt: localStorage.getItem("savedUserPrompt"),
        currentCode: currentFile,
        temperature: sliderValue / 100,

        uuid: localStorage.getItem("uuid"),
        model: localStorage.getItem("model"),
      })
      .then((res) => {
        refreshCredits();
        setGlobalGenerating(5); //back to normal
        setGenerating(false);

        if (res.data.main.length < 5) {
          res.data.main = res.data.full;
        }

        //switch res.data.canvas and res.data.main
        var temp = res.data.canvas;
        res.data.canvas = res.data.main;
        res.data.main = temp;
        console.log("canvas is ");
        console.log(res.data.canvas);

        PubSub.publish("code.change", res.data.canvas);

        PubSub.publish("code.finish", res.data.main);

        console.log(res.data.full);
      });
    // .catch((err) => {
    //   //if errorType is insufficient credits toast
    //   console.log(err);

    //   toast.error("Insufficient Credits, please upgrade your plan!");
    // });
  }
  return (
    <div>
      {" "}
      <div>
        <Box
          sx={{
            position: "fixed",
            top: 105,
            right: 16,
            scale: fabScale,
          }}
        >
          <Fab
            style={{
              //pink bg
              backgroundColor: "yellow",
            }}
            aria-label="add"
            onClick={handleClickOpen2}
          >
            <AddIcon />
          </Fab>
        </Box>
        <ThemeProvider theme={theme}>
          {/* <Dialog //THE FIRST DIALOG
            open={open1}
            onClose={handleClose1}
            aria-labelledby="dialog-title"
            maxWidth="md" // Adjust as per your requirement.
            fullWidth
          >
            <DialogTitle id="dialog-title">
              Generate with AI✨ - 1. Game Images and Background
            </DialogTitle>
            <DialogContent
              style={{
                width: "55vw",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",

                  alignItems: "center",
                  gap: "20px",
                  width: "100%",
                }}
              >
                <Typography variant="h7">
                  In this part, we'll go over the images and background and set
                  up your scene.
                </Typography>
                <Typography variant="h7">
                  After this you'll be able to visually edit the AI generated
                  Scene!
                </Typography>

                <Typography variant="h7">
                  What Images are in the Game, if possible please provide URLs
                  here!
                </Typography>
                <Typography variant="h7">
                  Ex. 'A bird from www.images.io/pixybird , a pipe'
                </Typography>
                <Typography variant="h7">
                  Ex. 'Pac man transparent png image'
                </Typography>
                <TextField
                  id="images"
                  variant="filled"
                  multiline
                  rows={3}
                  style={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: "10px",
                    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
                    width: "80%",
                    alignSelf: "center",
                  }}
                  onChange={(e) => setImages(e.target.value)}
                />

                <Typography variant="h7">
                  Background URL or description
                </Typography>
                <Typography variant="h7">
                  Ex. 'Use the image www.images.io/pixysexybackground.png '
                </Typography>
                <Typography variant="h7">
                  Ex. 'Flappy bird background '
                </Typography>
                <Typography variant="h7">Ex. 'Pac Man background '</Typography>
                <TextField
                  id="background"
                  variant="filled"
                  rows={3}
                  style={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: "10px",
                    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
                    width: "80%",
                    alignSelf: "center",
                  }}
                  onChange={(e) => setBackground(e.target.value)}
                />

                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    if (generating) {
                      return;
                    }
                    submitCanvasRequest();
                  }}
                  style={{
                    // backgroundColor: "#536DFE"
                    //dark pink and dark purple gradient background
                    background:
                      "linear-gradient(90deg, #FF00FF 0%, #800080 100%)",
                    color: "#FFF",
                    fontWeight: 600,
                    borderRadius: "10px",
                    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
                    width: "80%",
                    alignSelf: "center",
                  }}
                >
                  {generating
                    ? "Generating Please Wait..."
                    : " Submit Game Images Description🖼️"}
                </Button>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                style={{
                  color: "white",
                }}
                onClick={handleClose1}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog> */}
          <Dialog //THE SECOND DIALOG
            open={open2}
            onClose={handleClose2}
            aria-labelledby="dialog-title"
            maxWidth="md" // Adjust as per your requirement.
            fullWidth
          >
            <DialogTitle id="dialog-title">
              Generate with AI✨ - Tell us about your game
            </DialogTitle>
            <DialogContent
              style={{
                width: "55vw",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",

                  alignItems: "center",
                  gap: "20px",
                  width: "100%",
                }}
              >
                <Typography variant="h7">
                  What Images are in the Game, if possible please provide URLs
                  here!
                </Typography>
                <Typography variant="h7">
                  Ex. 'A bird from www.images.io/pixybird , a pipe'
                </Typography>
                <Typography variant="h7">
                  Ex. 'Pac man transparent png image'
                </Typography>
                <TextField
                  id="images"
                  variant="filled"
                  multiline
                  rows={3}
                  style={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: "10px",
                    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
                    width: "80%",
                    alignSelf: "center",
                  }}
                  onChange={(e) => setImages(e.target.value)}
                />
                <Typography variant="h7">
                  Background URL or description
                </Typography>
                <Typography variant="h7">
                  Ex. 'Use the image www.images.io/pixysexybackground.png '
                </Typography>
                <Typography variant="h7">
                  Ex. 'Flappy bird background '
                </Typography>
                <Typography variant="h7">Ex. 'Pac Man background '</Typography>
                <TextField
                  id="background"
                  variant="filled"
                  rows={3}
                  style={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: "10px",
                    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
                    width: "80%",
                    alignSelf: "center",
                  }}
                  onChange={(e) => setBackground(e.target.value)}
                />
                <Typography variant="body1">
                  We are making a game similar to? Ex. 'Flappy bird'
                </Typography>
                <TextField
                  id="game-similar-to"
                  variant="filled"
                  style={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: "10px",
                    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
                    width: "80%",
                    alignSelf: "center",
                  }}
                  onChange={(e) => setGameSimilarTo(e.target.value)}
                />
                <Typography variant="body1">
                  What Input Controls the Game?
                </Typography>
                <Typography variant="body1">Ex. 'Space to jump'</Typography>
                <Typography variant="body1">
                  Ex. 'WASD to Move, Mouse click to shoot'
                </Typography>
                <TextField
                  id="controls"
                  variant="filled"
                  style={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: "10px",
                    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
                    width: "80%",
                    alignSelf: "center",
                  }}
                  onChange={(e) => setControls(e.target.value)}
                />
                <Typography variant="body1">
                  Describe the game mechanics. What causes what to happen?
                </Typography>
                <Typography variant="body1">
                  The more detailed this is the better. Here is an example.
                </Typography>
                <Typography
                  variant="body1"
                  //how to make italic?
                  style={{
                    fontStyle: "italic",
                  }}
                >
                  Ex. 'There is gravity so the bird is falling natually. Pipes
                  are spawned every 2 seconds,
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    fontStyle: "italic",
                  }}
                >
                  and they approach you. If you hit a pipe, the game is over. '
                </Typography>
                <TextField
                  id="input-outcome"
                  multiline
                  rows={4}
                  variant="filled"
                  style={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: "10px",
                    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
                    width: "80%",
                    alignSelf: "center",
                  }}
                  onChange={(e) => setInputOutcome(e.target.value)}
                />
                <Typography
                  variant="h6"
                  style={{
                    //bold
                    fontWeight: 600,
                    //white text
                    color: "#FFFFFF",
                    //h3
                  }}
                >
                  Temperature🌡️: {sliderValue / 100} {" (Model Creativity)"}
                </Typography>
                <Typography
                  variant="h8"
                  style={{
                    //bold
                    fontWeight: 600,
                    //white text
                    color: "#FFFFFF",
                    //align self to center
                    alignSelf: "center",
                    textAlign: "center",
                    //h3
                  }}
                >
                  Default is set to 0.3. Lower values make it more deterministic
                  and repetitive. Higher values make it more creative and
                  random.
                </Typography>
                <Slider
                  value={sliderValue}
                  onChange={handleSliderChange}
                  aria-labelledby="continuous-slider"
                  style={{
                    width: "80%",
                    alignSelf: "center",
                    //pink dot and thick light blue line
                    color: "#FF00FF",
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: "20px",
                  }}
                >
                  <Switch
                    checked={useGPT4}
                    onChange={() => {
                      setUseGPT4(!useGPT4);
                    }}
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    style={{
                      //pink dot and thick light blue line
                      color: "#FF00FF",
                    }}
                  />
                  <div style={{ marginLeft: "10px" }}>
                    <Typography
                      variant="h6"
                      style={{
                        //bold
                        fontWeight: 600,
                        //white text
                        color: "#FFFFFF",
                      }}
                    >
                      Use GPT-4🤖️
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{
                        //bold
                        fontWeight: 600,
                        //white text
                        color: "#FFFFFF",
                      }}
                    >
                      GPT-4 is the most powerful LLM. It is slower but more
                      accurate than GPT-3.5.
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{
                        //bold
                        fontWeight: 600,
                        //white text
                        color: "#FFFFFF",
                      }}
                    >
                      {useGPT4
                        ? "GPT-4 is currently selected."
                        : "GPT-3.5 is currently selected."}
                    </Typography>
                  </div>
                </div>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    if (generating) {
                      return;
                    }
                    setGenerating(true);
                    submitFullGameRequest();
                  }}
                  style={{
                    // backgroundColor: "#536DFE"
                    //dark pink and dark purple gradient background
                    background:
                      "linear-gradient(90deg, #FF00FF 0%, #800080 100%)",
                    color: "#FFF",
                    fontWeight: 600,
                    borderRadius: "10px",
                    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
                    width: "80%",
                    alignSelf: "center",
                  }}
                >
                  {generating
                    ? "Generating Please Wait..."
                    : " Generate with AI✨"}
                </Button>
                {generating && <CircularProgress color="secondary" />}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    //IN NEW TAB , but not popup
                    window.open("https://discord.gg/RkH98RgVDr", "_blank");
                  }}
                  style={{
                    // backgroundColor: "#536DFE"
                    // blue and green gradient background
                    background:
                      "linear-gradient(90deg, #00FFFF 0%, #00FF00 100%)",

                    color: "#000",
                    fontWeight: 600,
                    borderRadius: "10px",
                    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
                    width: "80%",
                    alignSelf: "center",
                  }}
                >
                  <img
                    src={
                      "https://www.freepnglogos.com/uploads/discord-logo-png/discord-logo-logodownload-download-logotipos-1.png"
                    }
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "10px",
                    }}
                  />
                  Join Others on Discord!
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    //IN NEW TAB , but not popup
                    window.open(
                      "https://github.com/lil-shake-code/helicity.ai",
                      "_blank"
                    );
                  }}
                  style={{
                    // backgroundColor: "#536DFE"
                    // light blue and darker visually appealing blue gradient background
                    background:
                      "linear-gradient(90deg, #00FFFF 0%, #00BFFF 100%)",

                    color: "#000",
                    fontWeight: 600,
                    borderRadius: "10px",
                    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
                    width: "80%",
                    alignSelf: "center",
                  }}
                >
                  <img
                    src={"https://cdn-icons-png.flaticon.com/512/25/25231.png"}
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "10px",
                    }}
                  />
                  RUN LOCALLY
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    //IN NEW TAB , but not popup
                    window.open("https://helicity.gitbook.io/docs/", "_blank");
                  }}
                  style={{
                    // backgroundColor: "#536DFE"
                    // light yellow and orange gradient background
                    background:
                      "linear-gradient(90deg, #FFFF00 0%, #FFA500 100%)",

                    color: "#000",
                    fontWeight: 600,
                    borderRadius: "10px",
                    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
                    width: "80%",
                    alignSelf: "center",
                  }}
                >
                  <img
                    src={
                      "https://cdn.icon-icons.com/icons2/2699/PNG/512/gitbook_logo_icon_168173.png"
                    }
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "10px",
                    }}
                  />
                  View Documentation
                </Button>
                {/* <TextField
                  id="outlined-multiline-static"
                  label="AI Explanation"
                  multiline
                  rows={6}
                  //available variants are outlined, filled, and standard
                  variant="filled"
                  //outlined in white and text in white
                  style={{
                    backgroundColor: "#FFFFFF",
                    color: "#FFFFFF",
                    borderRadius: "10px",
                    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
                    width: "80%",
                    alignSelf: "center",
                  }}
                  value={explanation}
                /> */}
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                style={{
                  color: "white",
                }}
                onClick={handleClose2}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </ThemeProvider>
      </div>
    </div>
  );
}

export default IDE;
