import React, { useState, useRef, useEffect } from "react";
import { Stage, Layer, Image, Transformer } from "react-konva";
import useImage from "use-image";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const URLImage = ({ image }) => {
  const [img] = useImage(image.src);
  const shapeRef = useRef();
  const trRef = useRef();

  useEffect(() => {
    if (image.isSelected) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [image.isSelected]);

  return (
    <>
      <Image
        image={img}
        x={image.x}
        y={image.y}
        draggable
        ref={shapeRef}
        onClick={image.onSelect}
        onTap={image.onSelect}
        onDragEnd={(e) => {
          image.onMove({ x: e.target.x(), y: e.target.y() });
        }}
        onTransformEnd={(e) => {
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          node.scaleX(1);
          node.scaleY(1);
          image.onTransform({
            x: node.x(),
            y: node.y(),
            rotation: node.rotation(),
            width: Math.max(5, node.width() * scaleX),
            height: node.height() * scaleY,
          });
        }}
        {...image}
      />
      {image.isSelected && <Transformer ref={trRef} />}
    </>
  );
};

const CanvasEditor = () => {
  const [selectedId, selectShape] = useState(null);
  const [images, setImages] = useState(
    JSON.parse(localStorage.getItem("scene")) || []
  );
  const [imageUrls, setImageUrls] = useState(
    JSON.parse(localStorage.getItem("imageUrls")) || [
      "https://png.pngitem.com/pimgs/s/184-1842817_flappy-bird-bird-png-transparent-png.png",
      "https://w7.pngwing.com/pngs/111/173/png-transparent-green-pipe-illustration-new-super-mario-bros-2-pipe-flappy-bird-mario-pipe-thumbnail.png",
    ]
  );
  const [urlInput, setUrlInput] = useState("");

  useEffect(() => {
    localStorage.setItem("scene", JSON.stringify(images));
  }, [images]);

  useEffect(() => {
    localStorage.setItem("imageUrls", JSON.stringify(imageUrls));
  }, [imageUrls]);

  const stageRef = useRef();

  const handleDragStart = (e, url) => {
    e.dataTransfer.setData("image/url", url);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const url = e.dataTransfer.getData("image/url");
    const stage = stageRef.current;
    const point = stage.getPointerPosition();
    if (point) {
      setImages([
        ...images,
        {
          src: url,
          x: point.x,
          y: point.y,
          width: 100,
          height: 100,
        },
      ]);
    }
  };

  const handleAddImage = () => {
    if (urlInput !== "") {
      setImageUrls([...imageUrls, urlInput]);
      setUrlInput("");
    }
  };

  const handleRemoveImage = (index) => {
    const newImageUrls = [...imageUrls];
    newImageUrls.splice(index, 1);
    setImageUrls(newImageUrls);
  };

  const handleClearCanvas = () => {
    setImages([]);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",

        padding: "10px",
        borderRadius: "5px",
      }}
    >
      <Typography variant="h6" style={{ fontWeight: "bold", padding: 10 }}>
        Image Assets
      </Typography>
      <Card
        style={{
          padding: "10px",
          marginBottom: "10px",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <TextField
            value={urlInput}
            onChange={(e) => setUrlInput(e.target.value)}
            style={{ marginRight: 10 }}
            label="Image URL"
          />
          <Button variant="contained" color="primary" onClick={handleAddImage}>
            Add Image
          </Button>
        </div>
      </Card>

      <div // row view of images
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          overflow: "auto",
        }}
      >
        {imageUrls.map((url, index) => (
          <Card style={{ maxWidth: 100, maxHeight: 100, position: "relative" }}>
            <IconButton
              size="small"
              style={{ position: "absolute", top: 0, right: 0 }}
              onClick={() => handleRemoveImage(index)}
            >
              <CloseIcon />
            </IconButton>
            <CardMedia
              component="img"
              image={url}
              draggable="true"
              onDragStart={(e) => handleDragStart(e, url)}
              style={{
                height: 100,
                width: 100,
                objectFit: "contain",
              }}
            />
          </Card>
        ))}
      </div>

      <Typography variant="h6" style={{ fontWeight: "bold", padding: 10 }}>
        Drag and drop images from above to the canvas
      </Typography>
      <div
        onDrop={handleDrop}
        onDragOver={(e) => e.preventDefault()}
        style={{
          height: 720,
          width: 1280,
          position: "relative",
          background: "white",
          transform: "scale(0.6)",
          transformOrigin: "top left",
        }}
      >
        <Stage width={1280} height={720} ref={stageRef}>
          <Layer>
            {images.map((img, i) => {
              return (
                <URLImage
                  key={i}
                  image={{
                    ...img,
                    isSelected: img.id === selectedId,
                    onSelect: () => {
                      selectShape(img.id);
                    },
                    onMove: (newPos) => {
                      const imgs = images.slice();
                      imgs[i] = { ...imgs[i], ...newPos };
                      setImages(imgs);
                    },
                    onTransform: (newProps) => {
                      const imgs = images.slice();
                      imgs[i] = { ...imgs[i], ...newProps };
                      setImages(imgs);
                    },
                  }}
                />
              );
            })}
          </Layer>
        </Stage>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleClearCanvas}
          style={{ position: "absolute", bottom: 10, right: 10 }}
        >
          Clear Canvas
        </Button>
      </div>
    </div>
  );
};

export default CanvasEditor;
